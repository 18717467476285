import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { axios } from "../../../axios"
import Confirm from "../../my-com/confirm"



import style from "./index.module.css"
export default function Order() {
    const navi = useNavigate()
    //获取url参数 ?id=1
    const [searchParams,] = useSearchParams()

    const id = searchParams.get('id')
    useEffect(()=>{
        if(id===null){
            navi('/user/vip')
        }
        getOrderAndPayMethod()
        // eslint-disable-next-line
    },[id])

    const [order,setOrder] = useState(null)


    return <div className={style.container}>
        {order===null?<div>加载中... 请勿离开当前页面</div>:<>
        
        <div className={style.orderDetail}>
            <h3>订单详情</h3>
            <div>
                <div>订单号:{order.strId}</div>
                <div>增加天数:{order.days}</div>
                <div>金额:{order.money}</div>
                <div>
                    订单状态:{order.status===0?'待支付':order.status===1?'已支付':'已关闭'}
                </div>
            </div>
        </div>
        {order.status===1&&<div>
            订单已支付    
        </div>}
        {order.status===2&&<div>
            订单已关闭    
        </div>}

        {order.status===0&&<div className={style.payContainer}>

            {order.payMethod?<div>
                <div style={{textAlign:'center'}}>
                    <img src={order.payMethod.qrCode} alt="" width='80%' />
                </div>
                <div style={{fontWeight:'bold',color:'red'}}>
                    <p>{order.payMethod.title}</p>
                </div>
            </div>:<div style={{padding:"1em",color:'red',fontWeight:'bold'}}>支付通道配置获取失败,请联系客服获得其他支付方式</div>}
            <div className={style.tishi}>
                <p>基于对您的信任，点击确认已支付后系统会先给您增加成员卡天数，请在30分钟内按上面红色提示完成支付。</p>
                <p>超时未支付您的帐号将被封禁。</p>
                <p>或者,请您支付完成后再点击下方 确认已支付 按钮。</p>
            </div>

            <div className={style.btns}>
                <p className={style.confirm}>
                    <span onClick={confirmPaid}>确认已支付</span>
                </p>
                <p className={style.closeAndContact}>
                    <span 
                        onClick={()=>Confirm.confirm("确认需要客服帮助吗?").then(()=>navi('/attendant'))}
                    >遇到问题,联系客服</span>
                    <span onClick={closeOrder}>关闭订单</span>
                </p>
            </div>
            
        </div>}
        
        
        </>}
    </div>

    function confirmPaid() {
        Confirm.confirm("确认已经支付了吗？未支付请勿点击,否则您的帐号将被封禁")
        .then(()=>{
            axios.post("/api/pay/order/confirm",{
                id:id
            }).then(({data})=>{
                if(data.code===200){
                    navi('/user/vip')
                }else{
                    alert(data.msg)
                }
            })
        }).catch(()=>{})
    }
    function getOrderAndPayMethod() {
        axios.get("/api/pay/order/detail",{
            params:{
                id:id
            }
        }).then(({data})=>{
            if(data.code===200){
                setOrder(data.data)
            }
        })
    }


    function closeOrder(){
        Confirm.confirm("确定关闭订单吗？如果你已经支付完成,务必点击已支付按钮而不是关闭.如果确定放弃支付请继续")
        .then(()=>{
            axios.post("/api/pay/order/close",{
                id:id
            }).then(({data})=>{
                if(data.code===200){
                    navi('/user/vip')
                }
            })
        })
        .catch(()=>{})
    }
}