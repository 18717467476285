

import ReactDOM from 'react-dom/client';

import style from './index.module.css';
export default function Msg({msg,type}) {

    let className = style.bg;
    if(type === 1){
        className = style.red
    }else if(type === 2){
        className = style.green
    }else if(type === 3){
        className = style.blue
    }else{
        className = style.brown
    }
  return <div className={[className,style.bg].join(' ')}>
        <p>{msg}</p>
  </div>
}

Msg.msg = (msg,type,delay)=>{
    const div = document.createElement('div');
    document.body.appendChild(div);
    let tmpRoot = ReactDOM.createRoot(div);
    tmpRoot.render(<Msg msg={msg} type={type} />);
    setTimeout(()=>{
        tmpRoot.unmount();
        document.body.removeChild(div);
    }, delay || 2000);
}