import React from 'react';
import style from './index.module.css'
import { At } from './at';

// 定义用于匹配URL的正则表达式
const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
const atRegex = /(@[\w]+(?=\s|$))/g;
// Linkify组件

const Linkify = ({ text,id_pre }) => {

  // 检查文本是否包含URL
  if (!urlRegex.test(text) && !atRegex.test(text)) {
    return <>{text}</>;
  }

  // 分割文本成数组，根据URL进行分割
  const parts = text.split(urlRegex);

  // 查找所有URL
  const urls = text.match(urlRegex);
    return <>
        {parts.map((part, index) => {
            // 如果当前部分是URL，则创建一个a标签
            if (urls && urls.includes(part)) {
                return <React.Fragment key={part}>
                    {!isSuspar(part)?<a className={style.a} href={setUrl(part)} 
                        target= {isSuspar(part)?'_self':'_blank'}
                        rel="noopener noreferrer">
                    {part}
                    </a>:<a href={rmLocalhost(part)} className={style.b}>
                        {part}
                    </a>}
                </React.Fragment>
            }
            // 否则直接返回文本
            return <At key={index}>{part}</At>
        })}
    </>

    function setUrl(url){
        //url中不包含 bilibili.com 直接返回
        if(!url.includes("bilibili.com"))
            return url;
        //url中包含 space.bilibili.com时，提取space.bilibli.com/ID里ID的值，并将url改为 bilibili://space/ID
        if(url.includes("space.bilibili.com")){
        let id = url.split("space.bilibili.com/")[1];
        return `bilibili://space/${id}`;
        }
        //url中包含 www.bilibili.com/video/时，提取www.bilibli.com/video/ID里ID的值，并将url改为 bilibili://video/ID
        if(url.includes("www.bilibili.com/video/")){
            let id = url.split("www.bilibili.com/video/")[1];
            return `bilibili://video/${id}`;
        }
 
        return ""
    }
    function isSuspar(url){
        if(url.includes("suspar.com")){
            return true
        }
        if(url.includes("localhost:")){
            return true
        }
        if(url.includes("192.168.31.139")){
            return true
        }
        return false
    }
    function rmLocalhost(text){
        let url = new URL(text)
        let to = url.pathname+url.search
        console.log(to)
        return to
    }
};


export default Linkify;