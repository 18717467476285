import { createContext } from "react";



export const PAIRCONTEXT = createContext({
    flow:{
        pairSessionId:0,
        status:"",//当前session的状态，
        showStatus:"",//当前展示的状态
    }, 
    setFlow:()=>{},
    setSessionId:()=>{},
    canToNext:(v)=>{},
    check:()=>{}
});

