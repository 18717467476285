


import { useState } from "react"
import style from "./css/hider.module.css"


export function Hider({value,onChange}){

    const [v,setV] = useState(value)

    return <div className={style.bg}>
    <span className={v?style.hide:''} 
        onClick={()=>{
            setV(!v)
            onChange(!v)
        }}>匹配后可见</span>
    </div>
}


