import { useEffect, useState } from "react"
import { axios } from "../../../../axios"


import style from "./index.module.css"
import { useNavigate, useSearchParams } from "react-router-dom"
import Confirm from "../../../my-com/confirm"
import Flashs from "./flashs"
import Posts from "./posts"
import Replies from "./replies"
import ArchivedPosts from "./archived.posts"
import ArchivedReplies from "./archived.replies"
import { AvatarBaseURL } from "../../../../tools"

export default function UserProfile({username,userId}) {
    const navi = useNavigate()

    const [searchParams,setSearchParams] = useSearchParams()

    const [profile,setProfile] = useState({})

    const [card,setCard] = useState(1)

    
    useEffect(()=>{
        let tab = searchParams.get("tab")
        if(tab){
            setCard(parseInt(tab))
        }
        getUserProfile()
        // eslint-disable-next-line
    },[])

    return<div className={style.container}>
        <div className={style.items}>
            <div className={style.avatar}>
                <div>
                    <span className={style.placeholder}>头像</span>
                    <span>
                        <img src={AvatarBaseURL+profile.avatar} alt="avatar" />
                    </span>
                </div>
            </div>

            <div className={style.username}>
                <div className={style.item}>
                    <span className={style.placeholder}>帐号</span>
                    <span style={{color:"gray"}}>{profile.username}</span>
                </div>
                <div className={style.item}>
                    <span className={style.placeholder}>昵称</span>
                    <span>{profile.nickname}</span>
                </div>
            </div>

            <div className={style.itemNextLine}>
                <div className={style.placeholder}>个性签名:</div>
                <div className={style.content}>
                    <span className={style.text}>
                        {profile.signature}
                    </span>
                </div>
            </div>


            <div className={style.followerContainer}>
                <div>
                    <span>{profile.level}</span>
                    <span>等级</span>
                </div>
                <div>
                    <span>{profile.followCount}</span>
                    <span>关注</span>
                </div>
                <div>
                    <span>{profile.fansCount}</span>
                    <span>粉丝</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                    {profile.followed&&<span onClick={unfollow}>已关注</span>}
                    {!profile.followed&&<span onClick={follow}>关注</span>}
                </div>
            </div>
        </div>

        {profile.id&&!profile.privacy&&<div className={style.myActivity}>
            <div className={style.cardBtns}>
                <span onClick={()=>setTab(1)} className={card===1?style.active:""}>动态</span>
                <span onClick={()=>setTab(2)} className={card===2?style.active:""}>话题</span>
                <span onClick={()=>setTab(3)} className={card===3?style.active:""}>回复</span>
                <span onClick={()=>setTab(4)} className={card===4?style.active:""}>
                    <sup style={{fontSize:"0.5em"}}>收藏的</sup>话题
                </span>
                <span onClick={()=>setTab(5)} className={card===5?style.active:""}>
                    <sup style={{fontSize:"0.5em"}}>收藏的</sup>回复
                </span>
            </div>

            {profile.userId&&<>
                {card===1&&<Flashs userId={profile.userId} />}
                {card===2&&<Posts userId={profile.userId} />}
                {card===3&&<Replies userId={profile.userId}/>}
                {card===4&&<ArchivedPosts userId={profile.userId} />}
                {card===5&&<ArchivedReplies userId={profile.userId} />}
            </>}
        </div>}

        {profile.id&&profile.privacy&&<div className={style.privacy}>
            已开启隐私保护,动态、话题、回复不对外公开 😜    
        </div>}
    </div>

    //修改url中tab参数
    function setTab(tab){
        setCard(tab)
        setSearchParams((pre)=>{
            let newParams = new URLSearchParams(pre)
            newParams.set("tab",tab)
            newParams.set("page",1)
            return newParams
        })
    }

    function getUserProfile(){
        axios.get('/api/user/profile',{
            params:{
                userId:userId?userId:null,
                username:username?username:null
            }
        })
        .then(({data})=>{
            if(data.code===200){
                setProfile(data.data)
            }else if(data.code===400){
                alert(data.msg)
                navi(-1)
            }
        })
    }

    function follow(){
        if(!profile.userId){
            return
        }
        axios.post('/api/follower/follow',{
            userId:profile.userId
        })
        .then(({data})=>{
            if(data.code===200){
                getUserProfile()
            }
        })
    }

    function unfollow(){
        if(!profile.userId){
            return
        }
        Confirm.confirm("确定取消关注吗?").then(()=>{
            axios.delete('/api/follower/unfollow',{
                params:{
                    userId:profile.userId
                }
            })
            .then(({data})=>{
                if(data.code===200){
                    getUserProfile()
                }
            })
        })
    }

}









