
import { useEffect, useState } from "react"
import { InProgress } from "./inprogress"
import { Finished } from "./finished"
import { PAIRCONTEXT } from "./context"
import SquareFlow from "./flow"
import { useNavigate, useParams} from "react-router-dom"
import { axios } from "../../../axios"

// 打招呼记录  
import style from "./index.module.css"
import { CloseSquareFilled, DeleteFilled } from "@ant-design/icons"
export default function PairedRecord({check}){
    const navi = useNavigate()

    const id = useParams().id
    
    //正在进行inProgress 已结束finished
    const [tab,setTab] = useState("inProgress")

    const [flow,setFlow] = useState({
        show:null,//list正在进行和结束的列表 flow进入流程
    })

    useEffect(()=>{
        if(id){
            console.log("有id")
            init(id)
        }else{
            console.log("没有id")
            setFlow({
                show:'list',
                pairSessionId:-1,
                status:"",
                showStatus:""
            })
        }
     // eslint-disable-next-line
    },[id])
    function init(){
        axios.get("/api/square/flow/record-by-id",{
            params:{
                id:id
            }
        }).then(({data})=>{
            if(data.code===200){
                data = data.data
                setFlow({
                    show:'flow',
                    pairSessionId:data.id,
                    status:data.status,
                    showStatus:data.status
                })
            }
        }).catch(err=>{})
    }

    
    return <div className={style.container}>

        <PAIRCONTEXT.Provider value={{
            flow,
            setFlow:(v)=>{
                setFlow({
                    ...v, 
                    show:'flow'
                })
            },
            setSessionId:(id)=>{
                setFlow({
                    ...flow,
                    pairSessionId:id
                })
            },
            canToNext:(v)=>{
                const stats = ["CREATED","SAY_HELLO","FACE_PAIRING","READY_BE_LOVER","NOW_LOVER","BREAK_UP"]
                let statusIndex = stats.indexOf(flow.status)
                let showStatusIndex = stats.indexOf(v)
                if(showStatusIndex<statusIndex){
                    return true
                }
                return false
            },
            //检查vip和是否禁用功能
            check:()=>{
                check()
            }
        }}> 

            {flow.show==='list'&&<div>
                {tab==="inProgress"&&<div className={style.tabs}>
                    <span className={style.inProgress}
                        onClick={()=>setTab("inProgress")}>正在进行的流程</span> 
                    <span className={style.finished}
                        onClick={()=>setTab("finished")}><DeleteFilled /></span>
                </div>}
                {tab==="inProgress"&& <InProgress />}
                {tab==="finished"&& <Finished close={
                    ()=>setTab("inProgress")
                } />}
            </div>}
            
            {flow.show==='flow'&&<div>
                <div>
                    <span onClick={()=>{
                        if(!id){
                            setFlow({...flow,show:'list'})
                        }else{
                            setFlow({...flow,show:'list'})
                            navi("/square/paired")
                        }
                    }}><CloseSquareFilled /></span>
                </div>
                <SquareFlow />
            </div>}

        </PAIRCONTEXT.Provider>
    </div>

}