
import { useEffect, useState } from "react"
import { axios } from "../../../../axios"
import style from "./index.module.css"
import { genderStr, getSexRole } from "../dict"

export default function PreviewProfile(props){

    const [profile,setProfile] = useState({})
    useEffect(()=>{
        getPreviewProfile()
    },[])

    return <div className={style.container}>
        <div className={style.profile}>
            <h3>预览</h3>
            <h3 style={{fontSize:'16px'}}><span className={style.gray}>灰色</span>条目确认资料后才会释放可见</h3>
            <div className={style.hint}>
                <p>置灰隐藏内容将在双方进入下一步后释放可见，未匹配前内容在数据层面被隐藏，安全、隐私。</p>
                <p>你可以使用密钥分享功能与他人互换基本资料进入匹配流程。</p>
            </div>
            <div className={style.item}>
                <span>昵称</span>
                <div>{profile.name}</div>
            </div>

            <div className={style.item} >
                <span>性别</span>
                <div>{genderStr(profile.gender)}</div>
            </div>

            <div className={style.item}>
                <span>年龄</span>
                <div>{profile.age}</div>
            </div>

            <div className={[style.item,(profile.hideCode&(1<<17))!==0?style.hide:""].join(" ")}>
                <span>生日</span>
                <div>{profile.birthday}</div>
            </div>

            <div className={style.signature}>
                <span>个性签名</span>
                <div>{profile.signature}</div>
            </div>

            <div className={[style.item,(profile.hideCode&(1<<14))!==0?style.hide:""].join(" ")}>
                <span>性偏向</span>
                <div>{(profile.hideCode&(1<<14))===0?getSexRole(profile.sexRole):""}</div>
            </div>

            <div className={style.item}>
                <span>身高</span>
                <div>{profile.height}</div>
            </div>
            <div className={style.item}>
                <span>体重</span>
                <div>{profile.weight}</div>
            </div>
            <div className={style.item}>
                <span>所在城市</span>
                <div>
                    {profile.province?profile.province:''} {profile.city?profile.city:''}
                </div>
            </div>
            <div className={[style.item,(profile.hideCode&(1<<10))!==0?style.hide:""].join(" ")}>
                <span>家乡</span>
                <div>{profile.hometown}</div>
            </div>

            <div className={style.item}>
                <span>职业</span>
                <div>{profile.job}</div>
            </div>
            <div className={[style.item,(profile.hideCode&(1<<8))!==0?style.hide:""].join(" ")}>
                <span>年薪</span>
                <div>{profile.salary}</div>
            </div>
            <div className={style.item}>
                <span>学历</span>
                <div>{profile.education}</div>
            </div>

            <div className={[style.item,(profile.hideCode&(1<<6))!==0?style.hide:""].join(" ")}>
                <span>学校</span>
                <div>{profile.school}</div>
            </div>

            <div className={style.item}>
                <span>专业</span>
                <div>{profile.major}</div>
            </div>
            <div className={[style.detail,(profile.hideCode&(1<<4))!==0?style.hide:""].join(" ")}>
                <span>详细描述</span>
                <div>
                    {profile.detail}
                </div>
            </div>
            
            <div className={[style.hide,style.item].join(" ")}>
                <span>微信</span>
                <div>{profile.wechat}</div>
            </div>
            <div className={[style.hide,style.item].join(" ")}>
                <span>联系方式</span>
                <div>{profile.contact}</div>
            </div>
        </div>
    </div>


    function getPreviewProfile() {
        axios.get("/api/square/profile/preview",{

        }).then(({data})=>{
            if(data.code === 200){
                setProfile(data.data)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
} 



