import { useState } from "react"

import style from "./css/sexrole.module.css"
import { Hider } from "./hider"

export default function SexRoleEditor(props){

    const [value,setValue] = useState({
        value:props.value,
        hide:props.hide
    })

    return<div>
        <div>
            <h4>我比较偏向于做：</h4>
        </div>
        <div className={style.items}>
            <div className={value.value===0?style.active:''}
                onClick={()=>choice(0)}>0</div>
            <div className={value.value===1?style.active:''} 
                onClick={()=>choice(1)}>1</div>
            <div className={value.value===2?style.active:''} 
                onClick={()=>choice(2)}>1、0都行</div>
            <div className={value.value===3?style.active:''}
                onClick={()=>choice(3)}>1、0都不喜欢</div>
        </div>

        <Hider value={value.hide} onChange={(v)=>{
            setValue({...value,hide:v})
            props.back({...value,hide:v})
        }} />
    </div>

    function choice(v){
        let tmp = {...value,value:v}
        setValue(tmp)
        props.back(tmp)
    }
}