


import { useEffect, useRef, useState } from 'react';
import style from './index.module.css';
import { axios } from '../../axios';
import { localDate } from '../../tools';
export default function Attendant(){

    //        // {
        //     id:'',
        //     conten:'',
        //     dateTime:null,
        //     sendOrReceive:''
        // }
    const [msgs, setMsgs] = useState(null);
    const msgsRef = useRef(null)
    const maxMsgId = useRef(null)

    const gettingRef = useRef(false)

    const scrollRef = useRef(null)
    const firstRef = useRef(null)

    useEffect(()=>{
        firstGetMsg()
        //定时器 每10s获取一次新消息
        const timer = setInterval(()=>{
            getNewMsgs()
        },5000)
        if(scrollRef.current){
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        }
        return ()=>{
            clearInterval(timer)
        }
        // eslint-disable-next-line
    },[])
    useEffect(()=>{
        if(scrollRef.current && firstRef.current){
            console.log(scrollRef.current.scrollHeight,scrollRef.current.scrollTop)
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight
            firstRef.current = false
            //定位结束 不再滚动
        }
    },[msgs])


    const [newMessage, setNewMessage] = useState('');
    const handleSendMessage = (e) => {
        e.preventDefault();
        if(newMessage.trim().length===0){
            return
        }
        axios.post("/api/msg/send",{
            content:newMessage.trim()
        }).then(({data})=>{
            if(data.code===200){
                if (newMessage.trim()) {
                    getNewMsgs()
                    setNewMessage('');
                }
            }
        }).catch(err=>{})

    };


    return <div className={style.container}>
        
        <div className={style.msgContainer}>
            <div className={style.msgList} ref={scrollRef}>
                {msgs != null&&msgs.map((msg) => (
                <div 
                    key={msg.id} 
                    className={msg.sendOrReceive ? style.sentMessage : style.receivedMessage}
                >
                    <div>
                        <div className={style.content}>{msg.content}</div>
                        <div className={style.time}>{localDate(msg.dateTime)}</div>
                    </div>
                </div>
                ))}
            </div>
            
            <form className={style.messageInput} onSubmit={handleSendMessage}>
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="输入消息(最多保存50条)..."
                />
                <button type="submit">发送</button>
            </form>
        </div>
    </div>

    function getNewMsgs() {
        if(gettingRef.current){
            return
        }
        if(maxMsgId.current===null){
            firstGetMsg()
            return
        }
        gettingRef.current = true
        axios.get("/api/msg/get-new-msgs-after-id",{
            params:{
                id:maxMsgId.current
            }
        }).then(({data})=>{
            if(data.code===200){
                //按id正序排列
                let tmp = data.data.sort((a,b)=>a.id-b.id)
                //获取最大id
                if(tmp.length===0){
                    gettingRef.current = false
                    return
                }else{
                }
                let merged = [...msgsRef.current, ...tmp].filter((msg, index, self) =>{
                    return index === self.findIndex(t => t.id === msg.id);
                });
                setMsgs(merged)
                msgsRef.current = merged
                maxMsgId.current = Math.max(...merged.map(item => item.id));
                gettingRef.current = false
            }
        }).catch(err=>{
            gettingRef.current = false
        })
    }
    

    function firstGetMsg() {
        axios.get("/api/msg/get-new-msgs").then(({data})=>{
            if(data.code===200){
                //按id正序排列
                let msgs = data.data.sort((a,b)=>a.id-b.id)
                setMsgs(msgs)
                msgsRef.current = msgs

                //获取最大id
                maxMsgId.current = msgs[msgs.length-1].id
                firstRef.current = true
            }
        }).catch(err=>{
        })
    }



}