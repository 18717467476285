



import { useEffect, useState } from "react"
import { axios } from "../../../axios"
import style from "./index.module.css"
import { CheckOutlined, LockOutlined } from "@ant-design/icons"
import Confirm from "../../my-com/confirm"
export function Privacy(){

    const [profile,setProfile] = useState({

    })
    const [showAlter,setShowAlter] = useState(false)

    useEffect(()=>{
        getProfile()
    },[])

    return <div className={style.bg}>
        <h3>隐私设置</h3>

        <div className={style.items}>
            <div onClick={()=>setShowAlter(true)}>
                <span className={style.hint}>主页保护</span>
                <span>
                    {profile.privacy?<span>已开启</span>:<span>未开启</span>}
                </span>
            </div>
        </div>

        {showAlter && <div className={style.alterBg}>
            <div className={style.base}>
                <p className={style.title}>主页保护</p>
                <p className={style.description}>开启后你的主页会将动态、话题、回复对他人隐藏</p>
                <div className={style.items}>
                    <div onClick={privacyOpen}>
                        <span><LockOutlined />&nbsp;打开</span>
                        {profile.privacy&&<span style={{color:"goldenrod"}}><CheckOutlined /></span>}
                    </div>
                    <div onClick={privacyClose}>
                        <span><LockOutlined/>&nbsp;关闭</span>
                        {!profile.privacy&&<span style={{color:"goldenrod"}}><CheckOutlined /></span>}
                    </div>
                    
                </div>
                <p className={style.cancle} onClick={()=>setShowAlter(false)}>取消</p>
            </div>
        </div>}
    </div>

    function privacyOpen(){
        Confirm.confirm("确定开启隐私保护吗？").then(()=>{
            axios.post('/api/user/profile/privacy',{
                privacy:true
            })
            .then(({data})=>{
                if(data.code===200){
                    getProfile()
                    setShowAlter(false)
                }
            }).catch(err=>{
                console.log(err)
            })
        })
    }
    function privacyClose(){
        Confirm.confirm("确定关闭隐私保护吗？").then(()=>{
            axios.post('/api/user/profile/privacy',{
                privacy:false
            })
            .then(({data})=>{
                if(data.code===200){
                    getProfile()
                    setShowAlter(false)
                }
            }).catch(err=>{
                console.log(err)
            })
        })
    }


    function getProfile() {
        axios.get('/api/user/profile')
            .then(({data})=>{
                if(data.code===200){
                    let profile = data.data
                    setProfile(profile)
                    // new CACHE('profiles').add(userId,profile)
                }
            }).catch(err=>{
                console.log(err)
            })
    }
}