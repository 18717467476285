import { useState } from "react"

import { Input } from "antd"
import { Hider } from "./hider"
export default function SalaryEditor(props){


    const [value,setValue] = useState({
        value:props.value,
        hide:props.hide
    })

    return<div>
        <h4>设置年收入</h4>
        <div>
            <Input value={value.value} 
                style={{height:'3em',fontSize:'1.5em'}}
                placeholder='年收入' onChange={e=>{
                    let tmp = {...value,value:e.target.value}
                    setValue(tmp)
                    props.back(tmp)
                }} />
        </div>

        <Hider value={value.hide} 
            onChange={v=>{
                setValue({...value,hide:v})
                props.back({...value,hide:v})
        }} />
    </div>
}