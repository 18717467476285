


import { useEffect, useRef, useState } from "react"
import style from "./add.module.css"
import { CloseSquareFilled } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { axios } from "../../../axios"
import Msg from "../../my-com/Msg"
export function AddPageInNode(props){
    const navi = useNavigate()


    const titleRef = useRef(null)
    const contentRef = useRef(null)

    let cacheTitle = localStorage.getItem("cache_post_add_title")
    let cacheDescription = localStorage.getItem("cache_post_add_description")

    const [post,setPost] = useState({
        title:cacheTitle?cacheTitle:"",
        nodeId:props.nodeId,
        node:props.node,
        content:cacheDescription?cacheDescription:""
    })

    const [showAllNode,setShowAllNode] = useState(false)

    useEffect(()=>{
        contentRef.current.style.height = "auto"
        contentRef.current.style.height = contentRef.current.scrollHeight + "px";
    },[post.content])

    return <div className={style.bg}>
        <div className={style.container}>
        <div className={style.header}>
            <span onClick={()=>props.close(false)}><CloseSquareFilled /></span>
            <span style={{fontSize:'1.1em',fontWeight:'bold'}}>新建话题</span> 
            <span className={style.pubBtn} onClick={submitAdd}>发布</span>
        </div>

        <div style={{marginTop:'30px'}}>
            <p style={{fontWeight:'bold'}} onClick={()=>setShowAllNode(true)}>
                节点:&nbsp; {post.node ? post.node : "选择所属节点"}
            </p>
        </div>
        <div className={style.a}>
            <textarea ref={titleRef} value={post.title} placeholder="标题，5-50个字" onChange={v=>{
                titleRef.current.style.height = "auto"
                titleRef.current.style.height = titleRef.current.scrollHeight+"px"
                titleChange(v.target.value)
            }} />
        </div>
        <div className={style.b}>
            <textarea ref={contentRef} value={post.content} placeholder="正文，建议200-50000个字" onChange={v=>{
                contentRef.current.style.height = "auto"
                contentRef.current.style.height = contentRef.current.scrollHeight+"px"
                if(v.target.value.length <= 50000){
                    setPost({
                        ...post,
                        content:v.target.value
                    })
                    localStorage.setItem("cache_post_add_description",v.target.value)
                }
            }} />
        </div>

        {showAllNode && <AllNode close={()=>setShowAllNode(false)} backNode={v=>{
            setPost({
                ...post,
                nodeId:v.nodeId,
                node:v.node
            })
            setShowAllNode(false)
        }} />}
    </div>
    </div>

    //不能超过50个字
    function titleChange(v){
        if(v.length <= 50){
            setPost({
                ...post,
                title:v
            })
            localStorage.setItem("cache_post_add_title",v)
        }
    }

    function submitAdd(){
        if(!post.title){
            Msg.msg("标题不能为空")
            return
        }
        if(!post.nodeId){
            Msg.msg("请选择所属节点")
            return
        }
        if(!post.content){
            Msg.msg("正文不能为空")
            return
        }
        axios.post("/api/topic/post",{
            title:post.title,
            description:post.content,
            nodeId:post.nodeId
        }).then(({data})=>{
            if(data.code===200){
                localStorage.removeItem("cache_post_add_title")
                localStorage.removeItem("cache_post_add_description")

                let id = data.data
                navi("/topic/post/detail/"+id)
            }else{
                Msg.msg(data.msg)
            }
        }).catch(err=>{
        })
    }
}

function AllNode({close,backNode}){

    const [nodes,setNodes] = useState({
        total:0,
        list:[],
        page:1
    })

    useEffect(()=>{
        getAllNode()
        // eslint-disable-next-line
    },[])

    return <div className={style.allNodeBg}>
        <div>
            <p>
                <span><CloseSquareFilled onClick={()=>close()} /></span>
            </p>
            <div className={style.nodes}>
                {nodes.list.map((node,key)=><>
                    <span key={key} onClick={()=>{
                        backNode({nodeId:node.id, node:node.node})
                    }}>{node.node}</span>
                </>)}
            </div>
        </div>
    </div>

    function getAllNode(){
        axios.get("/api/topic/node/list",{
            params:{
                page:nodes.page-1,
            }
        }).then(({data})=>{
            if(data.code===200){
                setNodes({
                    ...nodes,
                    list:data.data.list
                })
            }
        }).catch(err=>{})
    }
}