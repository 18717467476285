import { useEffect, useState } from "react"
import { axios } from "../../../axios"

import style from "./index.module.css"
import { localDate } from "../../../tools"
// 成员卡 变动记录
export function VIPChangeRecord(){

    const [records,setRecords] = useState([])

    useEffect(()=>{
        getChangeRecords()
    },[])

    return <div className={style.container}>
        <h3>成员卡变动记录</h3>

        <div>
            <div className={style.header}>
                <div>增加天数</div>
                <div>增加后日期</div>
                <div>时间</div>
                <div>备注</div>
            </div>
            {records.map((record,index)=><div key={index} className={style.record}>
                <div>{record.day}</div>
                <div>{localDate(record.endDate)}</div>
                <div>{localDate(record.dateTime)}</div>
                <div>{record.remark}</div>
            </div>)}
        </div>

        <div className={style.hint}>
            <span>只展示最新50条</span>
        </div>
    </div>


    function getChangeRecords() {
        axios.get("/api/vip/change-records",{
        }).then(({data})=>{
            if(data.code===200){
                setRecords(data.data)
            }
        })
    }
}