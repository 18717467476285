



//children 是一个text，里面含有 @XXX空格 的内容，XXX只能是字母和数字

export function At({children}) {
    const text = children;

    // 定义用于匹配URL的正则表达式
    const urlRegex = /(@[\w]+(?=\s|$))/g;
    // 检查文本是否包含URL
    if (!urlRegex.test(text)) {
        return <span key={'default-a'}>{text}</span>;
    }

    // 分割文本成数组，根据URL进行分割
    const parts = text.split(urlRegex);
    const fucks = text.match(urlRegex);
    return <>
        {parts.map((part, index) => {
            // 如果当前部分是URL，则创建一个a标签
            if (fucks && fucks.includes(part)) {
                return (
                    <a style={{fontWeight:'bold',textDecoration:'none',color:'green'}} key={index} href={"/user/profile/"+part}>{part}</a>
                )
            }
            return part;
        })}
    </>
}