
import { useNavigate } from "react-router-dom"
import style from "./flashs.module.css"
import { useEffect, useState } from "react"
import { localDate, toFold } from "../../../../tools"
import { axios } from "../../../../axios"
import Confirm from "../../../my-com/confirm"
import Page from "../../../my-com/page"


// 我的动态
export default function Flashs(){
    const navi = useNavigate()
    useEffect(()=>{
        getFlashsOfUser(1)
        // eslint-disable-next-line
    },[])

    const [flashs,setFlashs] = useState({
        total:0,
        list:[],
        page:1
    })

    return <div className={style.flashContainer}>
        {flashs.list.map(e=><div key={e.id} className={[style.item,e.state===1?style.privateItem:''].join(" ")}
            >
            <div className={style.itemHeader}>
                <span className={style.user}>{getNicknameOrUsername(e.user)}</span>
                <span className={style.date}>{localDate(e.createDatetime)}</span>
            </div>
            <div onClick={()=>{navi("/flash/detail/"+e.id)}}>
                <p className={toFold(e.content)?style.hideContent:style.content}>{e.content}</p>
                {toFold(e.content)&&<span style={{fontSize:"0.9em",color:"#ababab"}}>...查看全部</span>}
            </div>
            <p className={style.footer}>
                <span>💬{e.commentCount}</span>
                <span>😋{e.up}</span>
                <span>🥵{e.down}</span>
                <span onClick={()=>del(e.id)}>删除</span>
                {e.state===0&&<span onClick={()=>{toPrivate(e.id)}}>私有化</span>}
            </p>
        </div>)}

        <div style={{margin:'1em 0'}}>
            <Page
                total={flashs.total}
                current={flashs.page}
                pageSize={18}
                onChange={p=>getFlashsOfUser(p)}
            />
        </div>
    </div>


    function getFlashsOfUser(page) {
        axios.get("/api/flash/of-mine",{
            params:{
                page:page-1
            }
        }).then(({data})=>{
            if(data.code===200){
                data = data.data
                setFlashs({...flashs,total:data.total,list:data.list,page})
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    function del(id){
        Confirm.confirm("确认删除吗？").then(()=>{
            axios.delete("/api/flash",{
                params:{
                    id
                }
            })
            .then(({data})=>{
                if(data.code===200){
                    getFlashsOfUser(flashs.page)
                }
            }).catch(err=>{
                    console.log(err)
            })
        }).catch(()=>{})
    }

    function toPrivate(id){
        Confirm.confirm("确认私有化吗？私有化后将仅你自己可见").then(()=>{
            axios.put("/api/flash/to-private",{
                id
            }).then(({data})=>{
                if(data.code===200){
                    getFlashsOfUser(flashs.page)
                }
            }).catch(err=>{
                console.log(err)
            })
        }).catch(()=>{})
    }

    function getNicknameOrUsername(user){
        if(user.nickname){
            return user.nickname
        }
        if(user.username){
            return user.username
        }
        return ""
    }
}