
import { useState } from "react";
import { axios } from "../../../axios";
import style from "./upload.module.css"
export function AvatarUpload({image,close}){


    const [uploadding,setUploadding] = useState(false)

    return<div className={style.bg}>
        <div>
            <div className={style.img}>
                <img src={image} alt="Preview" style={{ width: '100%', height: '100%' }} />
            </div>
            <p>
                <span onClick={()=>close()}>取消</span>
                <span onClick={exportImage}>提交</span>
            </p>
        </div>

        {uploadding && <div className={style.uploadding}>
            <div className={style.loading}>
                <div className={style.loadingIcon}>
                    <svg className={style.circular} viewBox="25 25 50 50">
                        <circle className={style.path} cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"></circle>
                    </svg>
                </div>
                <div className={style.loadingText}>上传中...</div>
            </div>
        </div>}
        
    </div>

    //导出png类型的图片
    //文件导出为500*500像素的png图片
    //上传这个文件到服务器 formdata的形式
    function exportImage(){
        setUploadding(true)
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 500;
        const img = new Image();
        img.src = image; // 从 props 获取的图片路径
        img.onload = () => {
            ctx.drawImage(img, 0, 0, 500, 500);

            // 导出 PNG 格式的图像
            canvas.toBlob((blob) => {
                upload(blob)
            }, 'image/jpeg', 0.3);
        }
    }
    async function upload(blob){
        let urlAndKey = await getPreUrl()
        if(!urlAndKey){
            alert('上传失败,请联系客服');
            setUploadding(false)
            return
        }
        fetch(urlAndKey.url,{
            method: 'PUT',
            body: blob,
            headers:{
                'Content-Type':'image/png',
                'Content-Length':blob.size
            },
            mode:'cors'
            }).then(response => {
                if (response.ok) {
                    confirm(urlAndKey.key)
                } else {
                    alert('上传失败,请联系客服');
                    setUploadding(false)
                }
            }
        )
    }
    async function getPreUrl(){
        let url = await axios.get("/api/user/avatar/preURL").then(({data})=>{
            if(data.code===200){
                return data.data
            }else{
                return null
            }
        })
        return url
    }
    function confirm(key) {
        axios.put("/api/user/avatar", {
            uri: key
        }).then(({data})=>{
            if(data.code===200){
                // 上传成功
                setUploadding(false)
                alert('上传成功')
                close(true);
            }else{
                // 上传失败
                alert(data.msg)
                setUploadding(false)
            }
        }).catch(err=>{
            alert(err)
            setUploadding(false)
        })
         // 关闭预览
    }
}
