import { useEffect } from "react"
import { axios } from "../../../../axios"
import { useState } from "react"
import style from "./replies.module.css"
import { Link, useSearchParams } from "react-router-dom"
import { localDate2, toFold } from "../../../../tools"
import Page from "../../../my-com/page"


// 我的回复
export default function Replies(){

    //从url获取分页参数
    const [searchParams,setSearchParams] = useSearchParams()

    useEffect(()=>{
        let page = searchParams.get("page")
        if(page){
            getMyReplies(parseInt(page))
        }else{
            getMyReplies(1)
        }
        // eslint-disable-next-line
    },[])

    const [replies,setReplies] = useState({
        list:[],
        page:1,
        total:0
    })

    return <div className={style.container}>
        {replies.list.map(e=><div key={e.id} className={style.item}>
            <div className={style.postTitle}>
                <p>
                    <Link to={'/topic/post/detail/'+e.postId}>{e.post}</Link>
                </p>
            </div>
            <div className={style.replyContentContainer}>
                <p
                    className={toFold(e.content)?style.hideReplyContent:style.replyContent}>
                        <Link to={'/topic/post/reply/'+e.id}>{e.content}</Link>
                </p>
                {toFold(e.content)&&<>{
                    <p className={style.folder}>
                        <span>
                            <Link style={{fontSize:"0.9em",color:"#ababab"}} to={'/topic/post/reply/'+e.id}>查看全部</Link>
                        </span>
                    </p>}</>}
                <p className={style.btns}>
                    <span className={style.btn}>
                        <span>😋{e.star}</span>
                        <span>🥵{e.unstar}</span>
                        <span>💬{e.replyCount}</span>
                    </span>
                    <span>{localDate2(e.createDatetime)}</span>
                </p>
            </div>
        </div>)}

        <div style={{margin:'1em 0'}}>
            <Page 
                total={replies.total}
                current={replies.page}
                pageSize={18}
                onChange={p=>getMyReplies(p)}
            />
        </div>
    </div>


    function getMyReplies(page) {
        axios.get("/api/topic/reply/list/of-mine",{
            params:{
                page:page-1
            }
        }).then(({data})=>{
            if(data.code===200){
                setReplies({...replies,list:data.data.list,total:data.data.total,page})
                setSearchParams((pre)=>{
                    let params = new URLSearchParams(pre)
                    params.set("page",page)
                    return params
                })
            }
        }).catch(er=>{console.log(er)})
    }
}