import { useEffect, useState } from "react"
import { axios } from "../../../../axios"
import { Link, useSearchParams } from "react-router-dom"


import style from "./posts.module.css"
import { localDate } from "../../../../tools"
import Page from "../../../my-com/page"


// 我的话题
export default function Posts({userId}){

    const [searchParams,setSearchParams] = useSearchParams()

    const [postList,setPostList] = useState({
        list:[],
        total:0,
        page:1,
    })

    useEffect(()=>{
        let page = searchParams.get("page")
        if(page){
            getMyPosts(parseInt(page))
        }else{
            getMyPosts(1)
        }
        // eslint-disable-next-line
    },[])

    return <div>
        <div>
            {postList.list.map(e=><div key={e.id} className={style.post}>
                    <p><Link to={'/topic/post/detail/'+e.id}>{e.title}</Link></p>
                    <span className={style.ss}>
                        <span>💬{e.replyCount}</span>
                        <span>😋{e.star}</span>
                        <span>🥵{e.unstar}</span>
                        <span className={style.date}>{localDate(e.createDatetime)}</span>
                    </span>
                </div>)}


                <div style={{margin:'1em 0'}}>
                    <Page
                        current={postList.page}
                        total={postList.total}
                        pageSize={18}
                        onChange={(i)=>{
                            getMyPosts(i)
                        }}
                    />
                </div>
        </div>
    </div>

    function getMyPosts(page) {
        axios.get("/api/topic/post/of-user",{
            params:{
                page:page - 1,
                userId
            }
        }).then(({data})=>{
            if(data.code === 200){
                setPostList({
                    ...postList,
                    total:data.data.total,
                    list:data.data.list,
                    page:page
                })
                setSearchParams((pre)=>{
                    let newParams = new URLSearchParams(pre)
                    newParams.set("page",page)
                    return newParams
                })
            }
        }).catch(err=>{console.log(err)})
    }
}