

import { Button } from 'antd'
import style from './pay.module.css'
import { useNavigate } from 'react-router-dom'
import { axios } from '../../axios'
export function Pay({money,close,type}){
    const navi = useNavigate()

    return <div className={style.container}>
        <div>
            <div className={style.tishi}>
                点击确认后请及时支付哦，超时未支付可能导致帐号被冻结。
            </div>

            <div className={style.btns}>
                <Button type='dashed' danger 
                    onClick={()=>{
                        createOrder()
                    }}
                    >我已了解并确认</Button> 
                <Button type='dashed' onClick={()=>close()}>取消</Button>
            </div>

            <div>
                <div>
                    <p style={{textAlign:'center',fontWeight:'bold'}}>需支付 {money} 元</p>
                </div>
            </div>
        </div>
    </div>


    function createOrder() {
        axios.post("/api/pay/order/create",{
            type:type,
        }).then(({data})=>{
            if(data.code===200){
                navi("/user/vip/order?id="+data.data.id)
            }else{
                alert(data.msg)
            }
        })
    }
}