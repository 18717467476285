import { LeftSquareOutlined } from "@ant-design/icons";





import style from "./index.module.css"
import { useContext, useEffect, useState } from "react";
import { PAIRCONTEXT } from "../../context";
import { axios } from "../../../../../axios";
import { localDate } from "../../../../../tools";
import { Button, Input } from "antd";
export function BreakUp(){
    const PAIRC = useContext(PAIRCONTEXT)
    const pairedSessionId = PAIRC.flow.pairSessionId

    const [breakupDetail,setBreakupDetail] = useState({
        "myRecord":null,
        "hisRecord":null,
    })

    const [whoAndTime,setWhoAndTime] = useState({
        who:"",
        time:"",
    })

    const [showAdd,setShowAdd] = useState(false)

    useEffect(()=>{
        getBreakupDetail()
        // eslint-disable-next-line
    },[])

    return <div>

        <div>
            <h2 style={{textAlign:"center"}}>分手。</h2>
        </div>

        <div className={style.breakupDetail}>
            <p className={style.timeContainer}>
                <span>{whoAndTime.who}在</span>
                <span className={style.time}>{localDate(whoAndTime.time)}</span>
                <span>提出了分手。</span>
            </p>
            <div className={style.reason}>
                {whoAndTime.who==='我'&&<>
                    <div className={style.a}>我的分手理由是</div>
                    <div className={style.b}>
                        {breakupDetail.myRecord.breakupReason}     
                        <div className={style.c}>{localDate(breakupDetail.myRecord.breakupDatetime)}</div>    
                    </div>   
                </>}
                {whoAndTime.who==='他'&&<>
                    <div className={style.a}>他的分手理由是</div>
                    <div className={style.b}>
                        {breakupDetail.hisRecord.breakupReason}
                        <div className={style.c}>{localDate(breakupDetail.hisRecord.breakupDatetime)}</div>
                    </div>
                </>}
            </div>

            {/* 补充 */}
            {whoAndTime.who==='我'&& !breakupDetail.hisRecord.inLove &&<div className={style.addReason}>
                <p className={style.a}>他的补充</p>
                <div className={style.b}>
                    {breakupDetail.hisRecord.breakupReason}
                    <div className={style.c}>{localDate(breakupDetail.hisRecord.breakupDatetime)}</div>
                </div>
            </div>}
            {whoAndTime.who==='他'&& !breakupDetail.myRecord.inLove &&<div className={style.addReason}>
                <p className={style.a}>我的补充</p>
                <div className={style.b}>
                    {breakupDetail.myRecord.breakupReason}
                    <div className={style.c}>{localDate(breakupDetail.myRecord.breakupDatetime)}</div>                    
                </div>    
            </div>}
        </div>

{/* 是否添加补充 */}
        {breakupDetail.myRecord && breakupDetail.myRecord.inLove && <div className={style.commentBtn}>
            <span onClick={()=>setShowAdd(true)}>分手补充</span>
        </div>}
        {showAdd && <AddComment 
            submit={(v)=>submitComment(v)}
            close={()=>setShowAdd(false)}/>}

        <div className={style.preOrNext}>
            <span className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"NOW_LOVER"})}>
                <LeftSquareOutlined />
            </span>
        </div>
    </div>


    function submitComment(v) {
        if(!v){
            return
        }
        axios.post("/api/square/flow/breakup-add-reason",{
            id:pairedSessionId,
            reason:v
        }).then(({data})=>{
            if(data.code===200){
                getBreakupDetail()
                setShowAdd(false)
            }
        }).catch(err=>{console.log(err)})
    }


    function getBreakupDetail() {
        axios.get("/api/square/flow/breakup",{
            params:{
                id:pairedSessionId
            }   
        }).then(({data})=>{
            if(data.code===200){
                setBreakupDetail({
                    myRecord:data.data.myRecord,
                    hisRecord:data.data.hisRecord
                })
                setWhoAndTime(getBreakupUserAndTime(data.data.myRecord,data.data.hisRecord))
            }
        }).catch(err=>{console.log(err)})
    }
}

function getBreakupUserAndTime(myRecord,hisRecord){
    if(!myRecord.inLove && hisRecord.inLove){
        return {
            who:"我",
            time:myRecord.breakupDatetime,
        }
    }
    if(!hisRecord.inLove && myRecord.inLove){
        return {
            who:"他",
            time:hisRecord.breakupDatetime,
        }
    }
    //时间在前的
    if(myRecord.breakupDatetime<hisRecord.breakupDatetime){
        return {
            who:"我",
            time:myRecord.breakupDatetime,
        }
    }else{
        return {
            who:"他",
            time:hisRecord.breakupDatetime,
        }
    }
}


function AddComment(props){
    const [v,setV] = useState("")
    return <div className={style.addComment}>
        <div>
            <div>
                <Input.TextArea 
                    value={v}
                    onChange={(e)=>setV(e.target.value)}
                    autoSize
                    placeholder="请填写你的分手补充"
                />
            </div>
            <div className={style.btns}>
                <Button onClick={()=>props.submit(v)} >提交</Button>
                <Button onClick={()=>props.close()}>取消</Button>
            </div>
        </div>

    </div>
}