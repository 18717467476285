

import { useContext, useEffect, useState } from "react"
import style from "./index.module.css"
import { PAIRCONTEXT } from "../../context"
import { LeftSquareOutlined, RightSquareOutlined } from "@ant-design/icons"
import { axios } from "../../../../../axios"
import { localDate } from "../../../../../tools"


export default function NowLover(){

    const PAIRC = useContext(PAIRCONTEXT)
    const pairedSessionId = PAIRC.flow.pairSessionId

    const [loveDetail,setLoveDetail] = useState({
        breakup:null,
        inLoveDatetime:null
    })
    useEffect(()=>{
        getLoveRecordDetail()
        // eslint-disable-next-line
    },[])

    return <div>
        <div>
            <h2 style={{textAlign:"center"}}>成为情侣</h2>
        </div>
        <div>
            <p>我们于{localDate(loveDetail.inLoveDatetime)}正式确立情侣关系啦</p>
        </div>

        <div className={style.preOrNext}>
            <span className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"READY_BE_LOVER"})}>
                <LeftSquareOutlined />
            </span>

            {PAIRC.canToNext('NOW_LOVER') && 
                <span  className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"BREAK_UP"})}>
                    <RightSquareOutlined />
                </span>}
        </div>

    </div>

    function getLoveRecordDetail() {
        axios.get("/api/square/flow/lover",{
            params:{
                id:pairedSessionId
            }   
        }).then(({data})=>{
            if(data.code===200){
                setLoveDetail({
                    breakup:data.data.breakup,
                    inLoveDatetime:data.data.inLoveDatetime
                })
            }
        }).catch(err=>{console.log(err)})
    }
}