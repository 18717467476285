import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./home";
import SquareProfile from "./profile";
import SquareSetting from "./setting";
import PreviewProfile from "./profile/preview";
import FindYou from "./findyou";
import PairedRecord from "./paired";
import Share from "./share";
import { axios } from "../../axios";

import style from "./index.module.css";
import { Passkey } from "./passkey/passkey";
import { Lover } from "./lover";
import { ProfileExample } from "./profile/example";
export default function Square(){

    const navi = useNavigate();
    const [checkStatus, setCheckStatus] = useState({
        vip:null,
        squareEnable:null
    });

    useEffect(()=>{
        check()
        // eslint-disable-next-line
    },[])


    return<div style={{position:'relative',height:'100%'}}>
        {checkStatus.vip!==null&&<>
            {!checkStatus.vip&&<><div className={style.msg} onClick={()=>{
                navi("/user/vip")
            }}>
                为避免小号、多号等帐号滥用，减少低质量匹配，需要获取成员卡后使用寻他功能
            </div>
            <Routes>
                <Route path="/" element={<Home enable={false} />} />
            </Routes></>}

            {checkStatus.vip&&<>
                {checkStatus.squareEnable?<Routes>
                    <Route path="/" element={<Home enable={true} />} />
                    <Route path="/profile" element={<SquareProfile />} />
                    <Route path="/profile/preview" element={<PreviewProfile />} />
                    <Route path="/profile/example" element={<ProfileExample />} />
                    <Route path="/setting" element={<SquareSetting setEnable={(v)=>{
                        setCheckStatus({
                            ...checkStatus,
                            squareEnable:v
                        })
                    }}/>} />
                    <Route path="/find" element={<FindYou />} />
                    <Route path="/paired" element={<PairedRecord check={()=>{
                        check()
                    }} />} />
                    <Route path="/paired/:id" element={<PairedRecord check={()=>{
                        check()
                    }} />} />
                    <Route path="/share" element={<Share />} />
                    <Route path="/share/passkey" element={<Passkey />} />
                    <Route path="/lover" element={<Lover />} />
                </Routes>:<Routes>
                    <Route path="/" element={<Home enable={false} />} />
                    <Route path="/setting" element={<SquareSetting setEnable={(v)=>{
                        setCheckStatus({
                            ...checkStatus,
                            squareEnable:v
                        })
                    }}/>} />
                    <Route path="/lover" element={<Lover />} />
                </Routes>}
            </>}
        </>}

        {checkStatus.vip===null&&<>
            <Routes>
                <Route path="/" element={<Home enable={false} loadding={true} />} />
            </Routes>
        </>}
    </div>


    function check(){
        let squareInfo = localStorage.getItem("squareInfo");
        if(squareInfo){
            squareInfo = JSON.parse(squareInfo);
            setCheckStatus({
                vip:squareInfo.vip,
                squareEnable:squareInfo.squareEnable
            })
            asyncCheck()
        }else{
            asyncCheck()
        }
    }
    async function asyncCheck(){
        axios.get("/api/square/status",{
        }).then(({data})=>{
            if(data.code===200){
                setCheckStatus({
                    vip:data.data.vip,
                    squareEnable:data.data.squareEnable
                })
                localStorage.setItem("squareInfo",JSON.stringify(data.data))
            }
        }).catch(err=>{})
    }
}