import { Button, Input } from "antd"
import { useState } from "react"

import style from "./like.module.css"
import { axios } from "../../../../../axios"

function Dislike(props){
    const [msg,setMsg] = useState("")

    return <div className={style.bg}>
        <div>
            <div>
                <p>请认真对待每一次爱意,尽可能表达友善真实的你。</p>
                <p>相遇就是一场缘分，如果实在不适合做爱人，也不必勉强。</p>
            </div>

            <div>
                <Input.TextArea autoSize={{
                            minRows: 2,
                            maxRows: 16,
                }} onChange={v=>setMsg(v.target.value)}/>
            </div>

            <div>
                <Button onClick={dislike}>提交</Button> <Button onClick={()=>{
                    props.close(false)
                }}>取消</Button>
            </div>
        </div>
    </div>

    //不合适
    function dislike(){
        //检验是否为空
        if(!msg){
            alert("请填写原因")
            return
        }
        axios.post("/api/square/flow/after-week/dislike",{
            sessionId:props.sessionId,
            comment:msg
        }).then(({data})=>{
            if(data.code===200){
                props.close(true)
            }else{
                alert(data.msg)
            }
        }).catch(err=>console.log(err))
    }
    
}

function Like(props){
    const [msg,setMsg] = useState("")
    return <div className={style.bg}>
        <div>
            <div>
                <p>请认真对待每一次相遇。</p>
                <p>认识这么些日子了，相处还愉快吗？</p>
                <p>双方都点击提交后 你们将确认恋人关系。祝你们幸福</p>
            </div>

            <div>
                <Input.TextArea autoSize={{
                    minRows: 2,
                    maxRows: 16,
                }} onChange={v=>setMsg(v.target.value)} />
            </div>

            <div>
                <Button onClick={like}>提交</Button> <Button onClick={()=>{
                    props.close(false)
                }}>取消</Button>
            </div>
        </div>
    </div>

    //继续沟通
    function like(){
        //检验是否为空
        if(!msg){
            alert("请填写原因")
            return
        }
        axios.post("/api/square/flow/after-week/like",{
            sessionId:props.sessionId,
            comment:msg
        }).then(({data})=>{
            if(data.code===200){
                props.close(true)
            }else{
                alert(data.msg)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    
    
}

export {Dislike,Like}