import React, { useState, useEffect, useRef } from 'react';
import styles from './index.module.css';
import { axios } from '../../axios';
import { localDate } from '../../tools';
import Msg from '../my-com/Msg';
import { CloseSquareFilled } from '@ant-design/icons';

const GameMine = () => {
  const [tiles, setTiles] = useState([]);
  const [bombs, setBombs] = useState([]);
  const [, setNumbers] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [boardSize, setBoardSize] = useState(8);
  const [tileSize] = useState(60);
  const [bombFrequency] = useState(0.2);
  const [showDifficulty, setShowDifficulty] = useState(false);
  const boardRef = useRef(null);
  const endscreenRef = useRef(null);

  const difficulties = [
    { name: '简单', size: 8 },
    { name: '中等', size: 20 },
    { name: '困难', size: 40 },
    { name: '专家', size: 50 },
    { name: '地狱', size: 100 }
  ];

  // 排行榜
  const [showRank, setShowRank] = useState({
    show: false,
    difficulty: '',
    myRecord: 0,
    rankList: []
  });

  const endscreenContent = {
    win: '<span>✔</span> You won!',
    loose: '💣 Booom! Game over.'
  };

  useEffect(() => {
    setup();
    return ()=>{
      if(timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
    // eslint-disable-next-line
  }, [boardSize, tileSize, bombFrequency]);

  const setup = () => {
    if (endscreenRef.current) {
      endscreenRef.current.innerHTML = '';
      endscreenRef.current.classList.remove(styles.show);
    }

    let newTiles = [];
    let newBombs = [];
    let newNumbers = [];
    let size = boardSize;
    let x = 0;
    let y = 0;

    for (let i = 0; i < size * size; i++) {
      newTiles.push({ x, y, number: null, checked: false, isBomb: false });
      if (Math.random() < bombFrequency) {
        newBombs.push({ x, y });
        if (x > 0) newNumbers.push({ x: x - 1, y });
        if (x < size - 1) newNumbers.push({ x: x + 1, y });
        if (y > 0) newNumbers.push({ x, y: y - 1 });
        if (y < size - 1) newNumbers.push({ x, y: y + 1 });
        if (x > 0 && y > 0) newNumbers.push({ x: x - 1, y: y - 1 });
        if (x < size - 1 && y < size - 1) newNumbers.push({ x: x + 1, y: y + 1 });
        if (y > 0 && x < size - 1) newNumbers.push({ x: x + 1, y: y - 1 });
        if (x > 0 && y < size - 1) newNumbers.push({ x: x - 1, y: y + 1 });
      }
      x++;
      if (x >= size) {
        x = 0;
        y++;
      }
    }

    newNumbers.forEach(coord => {
      let index = newTiles.findIndex(t => t.x === coord.x && t.y === coord.y);
      if (index !== -1 && !newTiles[index].isBomb) {
        newTiles[index].number = (newTiles[index].number || 0) + 1;
      }
    });

    newBombs.forEach(bomb => {
      let index = newTiles.findIndex(t => t.x === bomb.x && t.y === bomb.y);
      if (index !== -1) {
        newTiles[index].isBomb = true;
      }
    });

    setTiles(newTiles);
    setBombs(newBombs);
    setNumbers(newNumbers);
    setGameOver(false);

    if(timerRef.current) {
      clearInterval(timerRef.current);
    }
    setGamingTimes({
      times: 0
    })
    timerRef.current = null;
    gamingTimesStartRef.current = null;
    gamingTimesEndRef.current = null;
  };

  const handleDifficultySelect = (size) => {
    setBoardSize(size);
    setShowDifficulty(false);
    setup();
  };

  const gamingTimesStartRef = useRef(null);
  const gamingTimesEndRef = useRef(null);
  const [gamingTimes, setGamingTimes] = useState({
    times: 0
  });
  const timerRef = useRef(null);


  const handleTileClick = (tile) => {
    if (gameOver || tile.checked) return;

    if(!gamingTimesStartRef.current) {
        //第一次点击每秒增加1
        const timer = setInterval(pre => {
            setGamingTimes(pre => {
            return {
                times: pre.times + 1
            }
            });
        }, 1000);
        gamingTimesStartRef.current = new Date();
        timerRef.current = timer;
    }
    
    if (tile.isBomb) {
        endGame();
    } else {
        // 创建新的tiles数组
        const newTiles = [...tiles];
        const tileIndex = newTiles.findIndex(t => t.x === tile.x && t.y === tile.y);
        newTiles[tileIndex].checked = true;
        
        // 如果不是数字格，递归检查邻居
        if (!tile.number) {
            checkNeighbors(tile, newTiles);
        }
        // 最后统一更新状态
        setTiles(newTiles);
        // 检查胜利条件
        if (!tile.isBomb) {
            checkVictory(newTiles);
        }
    }
  }

  const checkNeighbors = (tile, currentTiles) => {
    const neighbors = [
      { x: tile.x - 1, y: tile.y - 1 }, { x: tile.x, y: tile.y - 1 }, { x: tile.x + 1, y: tile.y - 1 },
      { x: tile.x - 1, y: tile.y },                                    { x: tile.x + 1, y: tile.y },
      { x: tile.x - 1, y: tile.y + 1 }, { x: tile.x, y: tile.y + 1 }, { x: tile.x + 1, y: tile.y + 1 }
    ];

    neighbors.forEach(neighbor => {
      if (neighbor.x >= 0 && neighbor.x < boardSize && 
          neighbor.y >= 0 && neighbor.y < boardSize) {
        const index = currentTiles.findIndex(t => 
          t.x === neighbor.x && t.y === neighbor.y
        );
        if (index !== -1 && !currentTiles[index].checked && !currentTiles[index].isBomb) {
          currentTiles[index].checked = true;
          if (!currentTiles[index].number) {
            checkNeighbors(currentTiles[index], currentTiles);
          }
        }
      }
    });
    setTiles([...currentTiles]);
  };



    const endGame = () => {
        setGameOver(true);
        let endDate = new Date();
        timerRef.current && clearInterval(timerRef.current);
        gamingTimesEndRef.current = endDate;
        
        if (endscreenRef.current) {
            //用了X秒XXX毫秒
            let tmp = endDate - gamingTimesStartRef.current;
            let seconds = Math.floor(tmp / 1000);
            let mseconds = tmp % 1000;
            endscreenRef.current.innerHTML = endscreenContent.loose+`<br>用时${seconds}.${mseconds}秒`;
            endscreenRef.current.classList.add(styles.show);
        }
        revealBombs();
    }

    const checkVictory = (newTiles) => {
        console.log(newTiles);
        const isWin = newTiles.every(tile => {
            return tile.checked || tile.isBomb;
        });
        if (isWin) {
            //清除定时器
            let endDate = new Date();
            timerRef.current && clearInterval(timerRef.current);
            gamingTimesEndRef.current = endDate;

            let tmp = endDate - gamingTimesStartRef.current;
            let seconds = Math.floor(tmp / 1000);
            let mseconds = tmp % 1000;

            if (endscreenRef.current) {
                endscreenRef.current.innerHTML = endscreenContent.win+`<br>用时${seconds}.${mseconds}秒`;
                endscreenRef.current.classList.add(styles.show);
            }
            sendToback(tmp);
            setGameOver(true);
        }
    }

    const revealBombs = () => {
        let newTiles = [...tiles];
        bombs.forEach(bomb => {
        let index = newTiles.findIndex(t => t.x === bomb.x && t.y === bomb.y);
        if (index !== -1) {
            newTiles[index].checked = true;
        }
        });
        setTiles(newTiles);
    }

  return <div className={styles.container}>
      <div className={styles.board} ref={boardRef} style={{ gridTemplateColumns: `repeat(${boardSize}, 60px)`, gridTemplateRows: `repeat(${boardSize}, 1fr)` }}>
            {tiles.map((tile, index) => (
            <div
                key={index}
                className={`${styles.tile} 
                        ${tile.checked ? styles.checked : ''} 
                        ${tile.checked && tile.isBomb ? styles.mine : ''}`}
                style={{ width: tileSize, height: tileSize }}
                onClick={() => handleTileClick(tile)}>
                {(tile.checked && tile.isBomb) ? '💣' : 
                (tile.checked && tile.number) ? tile.number : ''}
                {/* {tile.isBomb?'💣':''} */}
            </div>
            ))}
      </div>

      <div className={styles.controls}>
            <div className={styles.times}>
            <span>{gamingTimes.times}</span>
            </div>
        <div className={styles.btns}>
            <button className={styles.btn} onClick={setup}>重新开始</button>
            <button className={styles.btn} onClick={() => setShowDifficulty(true)}>难度设置</button>
            <button className={styles.btn} onClick={()=>{
                setShowRank({
                show: true,
                difficulty: '',
                myRank: 0,
                rankList: []
                })
                getRankOfDifficulty(8)
            }}>排行榜</button>
        </div>
      </div>

      {showDifficulty && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h2>选择难度</h2>
            <div className={styles.difficultyButtons}>
              {difficulties.map((diff) => (
                <button
                  key={diff.size}
                  className={styles.difficultyBtn}
                  onClick={() => handleDifficultySelect(diff.size)}
                >
                  {diff.name} ({diff.size}x{diff.size})
                </button>
              ))}
            </div>
            <button 
              className={styles.closeBtn}
              onClick={() => setShowDifficulty(false)}
            >
              关闭
            </button>
          </div>
        </div>
      )}
      <div className={styles.endscreen} ref={endscreenRef}></div>

      {showRank.show && <div className={styles.rankBg}>
        <div>
            <p>
            <span onClick={()=>setShowRank({
              show: false,
              difficulty: '',
              myRank: 0,
              rankList: []
            })}><CloseSquareFilled /></span>
          </p>

          <div>
                <div className={styles.title}>
                    <span className={showRank.difficulty===8?styles.active:''} onClick={()=>getRankOfDifficulty(8)}>简单</span>
                    <span className={showRank.difficulty===20?styles.active:''} onClick={()=>getRankOfDifficulty(20)}>中等</span>
                    <span className={showRank.difficulty===40?styles.active:''} onClick={()=>getRankOfDifficulty(40)}>困难</span>
                    <span className={showRank.difficulty===50?styles.active:''} onClick={()=>getRankOfDifficulty(50)}>专家</span>
                    <span className={showRank.difficulty===100?styles.active:''} onClick={()=>getRankOfDifficulty(100)}>地狱</span>
                </div>

                <div className={styles.rankList}>
                    <div className={styles.head}>
                        <span>用户</span>
                        <span>用时(s)</span>
                        <span>时间</span>
                    </div>
                    {showRank.rankList.map((item,index)=><div key={index}>
                        <span className={styles.user}>{item.user}</span>
                        <span className={styles.times}>{Math.floor(item.times/1000)+"."+item.times%1000}</span>
                        <span className={styles.createTime}>{localDate(item.createTime)}</span>
                    </div>)}

                    {showRank.myRecord&&<p>🎉 我的最少用时：
                      <span style={{fontSize:'2em'}}>{Math.floor(showRank.myRecord.times/1000)+"."+showRank.myRecord.times%1000}</span>秒！！ 🎉</p>}
                </div>
          </div>

        </div>
          
      </div>}
    </div>

    function sendToback(milli) {
      axios.post("/api/game/mine",{
        times: milli,
        difficulty:boardSize
      }).then(({data})=>{
        if(data.code===200){
          if(data.msg){
            Msg.msg(data.msg)
          }
        }
      }).catch(err=>{})
    }

    function getRankOfDifficulty(difficulty) {
      axios.get("/api/game/mine/rank",{
        params:{
          difficulty
        }
      }).then(({data})=>{
        if(data.code===200){
          let tmp = data.data
          setShowRank({
            show: true,
            difficulty,
            myRecord: tmp.myRecord,
            rankList: tmp.top10
          })
        }

      }).catch(err=>{})

    }
}

export default GameMine;

