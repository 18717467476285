import { useParams, useSearchParams } from "react-router-dom"
import { axios } from "../../../axios"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { NewList } from "./new"
import { SelectedList } from "./selected"

import style from "./index.module.css"
import { CACHE } from "../../../tools"
import { AddPageInNode } from "./add"
import Msg from "../../my-com/Msg"
import Confirm from "../../my-com/confirm"
export default function NodeDetail(){
    const id = useParams().id
    const [searchParams,setSearchParams] = useSearchParams()
    useEffect(()=>{
        getNodeDetail(id)
        // eslint-disable-next-line
    },[searchParams])

    const pageRef = useRef(1)
    const tabRef = useRef("new")
    useEffect(()=>{
        pageRef.current = searchParams.get("page")?parseInt(searchParams.get("page")):1
        tabRef.current = searchParams.get("tab")?searchParams.get("tab"):"new"

        setPosts(pre=>{
            return {...pre,page:pageRef.current}
        })
        setSelected(pre=>{
            return {...pre,page:pageRef.current}
        })
    },[searchParams])

    const [node,setNode] = useState({})

    //最新列表 
    const [posts,setPosts] = useState({
        list:[],
        total:0,
        page:null
    })
    function getPosts(tmpPage,changeURL){
        let cacheKey = "NodeDetail-newest-"+id+"-"+tmpPage
        new CACHE("API").get(cacheKey).then((data)=>{
            setPosts({...posts,
                list:data.list,
                total:data.total,
                page:tmpPage
            })
            if(changeURL){
                setSearchParams(pre=>{
                    let params = new URLSearchParams(pre)
                    params.set("page",tmpPage)
                    params.set("tab","new")
                    return params
                })
            }
        }).catch(err=>{
            axios.get("/api/topic/post/posts-of-node",{
                params:{
                    nodeId:id,
                    page:tmpPage?tmpPage-1:posts.page-1
                }
            }).then(({data})=>{
                setPosts({...posts,
                    list:data.data.list,
                    total:data.data.total,
                    page:tmpPage?tmpPage:posts.page
                })
                if(changeURL){
                    setSearchParams(pre=>{
                        let params = new URLSearchParams(pre)
                        params.set("page",tmpPage?tmpPage:posts.page)
                        params.set("tab","new")
                        return params
                    })
                }
                new CACHE("API").add(cacheKey,data.data)
            }).catch(err=>{})
        })
    }

    //精选列表
    const [selected,setSelected] = useState({
        list:[],
        total:0,
        page:1,
    })
    function getSelected(page,changeURL){
        let cacheKey = "NodeDetail-selected-"+id+"-"+page
        new CACHE("API").get(cacheKey).then(data=>{
            setSelected({
                list:data.list,
                total:data.total,
                page:page
            })
            if(changeURL){
                setSearchParams(pre=>{
                    let params = new URLSearchParams(pre)
                    params.set("page",page)
                    return params
                })
            }
        }).catch(err=>{
            axios.get("/api/topic/post/selected-of-node",{
                params:{
                    page:page-1,
                    nodeId:id
                }
            }).then(({data})=>{
                setSelected({
                    list:data.data.list,
                    total:data.data.total,
                    page:page,
                })
                if(changeURL){
                    setSearchParams(pre=>{
                        let params = new URLSearchParams(pre)
                        params.set("page",page)
                        return params
                    })
                }
                new CACHE("API").add(cacheKey,data.data)
            }).catch(err=>{})
        })
    }



    const [showAdd,setShowAdd] = useState(false)
    const [showDescription,setShowDescription] = useState(false)


    // 滚动
    const scrollRef = useRef(null)
    useLayoutEffect(()=>{
        const container = scrollRef.current
        return()=>{
            if(container){
                localStorage.setItem("TOPIC-Node-scrollTop",container.scrollTop)
            }
        }
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(scrollRef.current){
            let top = localStorage.getItem("TOPIC-Node-scrollTop")
            if(top){
                scrollRef.current.scrollTop = top
            }
        }
        // eslint-disable-next-line
    },[posts,selected])
    

    return <div className={style.container} ref={scrollRef}>
        <div className={style.nodeDetail}>
            <h3 className={style.title}>
                # {node.node}
                <span onClick={()=>displyDescription(true)}>?</span>
            </h3>
            <div className={style.btns}>
                <div>
                    <span className={style.addBtn} onClick={()=>{
                        setShowAdd(true)
                    }}>新建话题</span>
                </div>
                <div className={style.archiveBtn}>
                    {node.archived?<span onClick={unarchiveTopic}>取消收藏</span>:
                    <span onClick={archiveTopic}>收藏节点</span>}
                </div>
            </div>
        </div>


        <div className={style.posts}>
            <p className={style.btns}>
                <span className={style.left}>
                    <span className={tabRef.current==='new'?style.active:''}
                        onClick={()=>{
                            tabRef.current = "new"
                            setSearchParams(pre=>{
                                let params = new URLSearchParams(pre)
                                params.set("tab","new")
                                params.set("page","1")
                                return params
                            })
                        }}>
                        最新
                    </span>
                    <span className={tabRef.current==='selected'?style.active:''}
                        onClick={()=>{
                            tabRef.current = "selected"
                            setSearchParams(pre=>{
                                let params = new URLSearchParams(pre)
                                params.set("tab","selected")
                                params.set("page","1")
                                return params
                            })
                            localStorage.removeItem("TOPIC-Node-scrollTop")
                        }}>
                        精选
                    </span>
                </span>
                
                <span className={style.refresh} onClick={()=>{
                    new CACHE("API").delByPrefix("NodeDetail").then(()=>{
                        getPosts(1,true)
                        getNodeDetail(id)
                        localStorage.removeItem("TOPIC-Node-scrollTop")
                        Msg.msg("刷新成功")
                    })

                }}>刷新</span>
            </p>

            {tabRef.current==='new'&&<NewList id={id} 
                posts={posts}
                getPosts={getPosts} />}
            {tabRef.current==='selected'&&<SelectedList page={selected.page}
                list={selected}
                getSelected={(p,changeURL)=>getSelected(p,changeURL)} />}
        </div>

        {showAdd&&<AddPageInNode nodeId={node.id} node={node.node} close={(v)=>{
            setShowAdd(false)
            if(v){
            }
        }} />}


        {showDescription&&<div className={style.description}>
            <div>
                <div className={style.content}>
                    <div>
                        <span onClick={()=>displyDescription(false)} className={style.closeBtn}>关闭</span>
                    </div>
                    <div>
                        {node.description}
                    </div>
                </div>
            </div>
        </div>}
    </div>

    function displyDescription(v) {
        setShowDescription(v)
    }


    function getNodeDetail(id) {
        let cacheKey = "NodeDetail-"+id
        new CACHE("API").get(cacheKey).then(data=>{
            if(data){
                setNode(data)
            }else{
                axios.get("/api/topic/node",{
                    params:{
                        id
                    }
                }).then(({data})=>{
                    if(data.code===200){
                        setNode(data.data)
                        new CACHE("API").add(cacheKey,data.data)
                    }
                }).catch(err=>{})
            }
        }).catch(err=>{
            axios.get("/api/topic/node",{
                params:{
                    id
                }
            }).then(({data})=>{
                if(data.code===200){
                    setNode(data.data)
                    new CACHE("API").add(cacheKey,data.data)
                }
            }).catch(err=>{})
        })
    }


    function archiveTopic(){
        axios.post("/api/topic/node/archive",{
            nodeId:id
        }).then(({data})=>{
            if(data.code===200){
                getNodeDetail(id)
                new CACHE('API').del("TOPIC-archivedNodes")
                new CACHE('API').del("NodeDetail-"+id).then(()=>{
                    getNodeDetail(id)
                })
            }
        }).catch(err=>{})
    }
    function unarchiveTopic(){
        Confirm.confirm("确定取消收藏该节点吗?").then(()=>{
            axios.delete("/api/topic/node/archive",{
                params:{
                    nodeId:id
                }
            }).then(({data})=>{
                if(data.code===200){
                    getNodeDetail(id)
                    new CACHE('API').del("TOPIC-archivedNodes")
                    new CACHE('API').del("NodeDetail-"+id).then(()=>{
                        getNodeDetail(id)
                    })
                }
            }).catch(err=>{})
        })
    }


}



