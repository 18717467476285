import React from "react";
import ReactDOM from 'react-dom/client';

import style from './index.module.css'
import { Button } from "antd";
export default class Confirm extends React.Component{

    render(){
        return <div className={style.bg}>
            <div className={style.container}>
                <div className={style.msg}>
                    <p>{this.props.msg}</p>
                </div>

                <div className={style.btns}>
                    <p>
                        <Button type="dashed" onClick={this.props.success}>确认</Button>
                        <Button type="dashed" danger  onClick={this.props.fail}>取消</Button>
                    </p>
                </div>

            </div>

        </div>
    }


    static confirm(msg){
        let node = document.createElement("div")
        document.body.appendChild(node)
        let tmpRoot = ReactDOM.createRoot(node)

        let resolveTmp,rejectTmp;
        let promise = new Promise((resolve,reject)=>{
            resolveTmp=resolve;
            rejectTmp=reject;
        })

        tmpRoot.render(<Confirm msg={msg} success={()=>{
            tmpRoot.unmount()
            document.body.removeChild(node)
            resolveTmp("yes")
        }} fail={()=>{
            tmpRoot.unmount()
            document.body.removeChild(node)
            rejectTmp("no")
        }}
        />)
        return promise;
    }
}






