import { useContext } from "react"
import { SusparContext } from "../../context"
import UserLogged from "."
import { UserUnlogged } from "./unlogged"




export default function User(){
    
    const SUSPAR = useContext(SusparContext)
    console.log(SUSPAR)
    return <>
        {SUSPAR.loggedIn&&<UserLogged />}   
        {!SUSPAR.loggedIn&&<UserUnlogged />}
    </>
}