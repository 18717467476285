import { useState } from "react"
import Btn from "../../../my-com/btn"
import NameEditor from "./name"
import { axios } from "../../../../axios"
import GenderEditor from "./gender"
import AgeEditor from "./age"
import { checkDate } from "../../../../tools"
import BirthdayEditor from "./birthday"
import SignatureEditor from "./signature"
import SexRoleEditor from "./sexrole"
import BaseEditor from "./base"
import DetailEditor from "./detail"
import EducationEditor from "./education"

import style from "./css/index.module.css"
import HometownEditor from "./hometown"
import SalaryEditor from "./salary"
import SchoolEditor from "./school"
import ContactEditor from "./contact"
import CityEditor from "./city"
import Msg from "../../../my-com/Msg"
import WechatEditor from "./wechat"

export default function Editors(props){

    const type = props.type

    const [value,setValue] = useState({
        value:props.value,
        hide:hideOrNot(props.hideCode)
    })


    //判断code那一位是不是1
    function hideOrNot(showCode){
        let DICT_CODE;
        if(type==='name'){
            DICT_CODE = 1<<19;
        }
        if(type==='gender'){
            DICT_CODE = 1<<16;
        }
        if(type==='birthday'){
            DICT_CODE = 1<<17;
        }
        if(type==='sexRole'){
            DICT_CODE = 1<<14;
        }
        if(type==='hometown'){
            DICT_CODE = 1<<10;
        }
        if(type==='salary'){
            DICT_CODE = 1<<8;
        }
        if(type==='school'){
            DICT_CODE = 1<<6;
        }
        if(type==='detail'){
            DICT_CODE = 1<<4
        }
        if(type==='contact'){
            DICT_CODE = 1<<3;
        }
        if((showCode & DICT_CODE) === 0){
            return false
        }else{
            return true
        }
    }


    return <div className={style.editor}>
        <div className={style.btnHeader}>
            <Btn onClick={()=>props.close()} text="取消"/>
            <Btn confirm onClick={submit} text="确定"/>
        </div>
        {type==="name"&&
            <NameEditor 
                value={value.value} 
                hide={value.hide} 
                back={(v)=>setValue(v)} />}

        {type==="gender" &&
            <GenderEditor 
                value={value.value} 
                hide={value.hide} 
                back={(v)=>setValue(v)} />} 
                {/* 返回的是对象 */}
        {type==="age"&&
            <AgeEditor value={value.value} back={(v)=>setValue({...value,value:v})} />}
            {/* 返回的是值 */}
        {type==="birthday"&& 
            <BirthdayEditor 
                value={value.value} 
                hide={value.hide}
                back={(v)=>setValue(v)} />}

        {type==='signature' && 
            <SignatureEditor value={value.value} back={(v)=>setValue({...value,value:v})} />}
        {type==='sexRole' && 
            <SexRoleEditor 
            value={value.value} 
            hide={value.hide}
            back={(v)=>setValue(v)} />}

        {type==='hometown' && 
            <HometownEditor value={value.value} 
                title="家乡"
                hide={value.hide}
                back={(v)=>setValue(v)} />}

        {type==='salary' && 
            <SalaryEditor value={value.value} 
                title="年薪"
                hide={value.hide}
                back={(v)=>setValue(v)} />}

        {type==='school' && 
            <SchoolEditor value={value.value} 
                title="学校"
                hide={value.hide}
                back={(v)=>setValue(v)} />}

        {type==='contact' && 
            <ContactEditor value={value.value} 
                title="联系方式"
                hide={value.hide}
                back={(v)=>setValue(v)} />}
        {type==='wechat' && 
            <WechatEditor 
                value={value.value} 
                title="微信"
                ph="微信帐号"
                back={(v)=>setValue({...value,value:v})} />}
        {type==='height' && 
            <BaseEditor 
                value={value.value} 
                title="身高"
                ph="身高"
                back={(v)=>setValue({...value,value:v})} />}
        {type==='weight' && 
            <BaseEditor 
                value={value.value} 
                title="体重"
                ph="体重"
                back={(v)=>setValue({...value,value:v})} />}
        {type==='province' && 
            <CityEditor value={value.value}
                back={(v)=>setValue({...value,value:v})}
            />
        }
        {type==='job' && 
            <BaseEditor value={value.value} 
                title="职业"
                ph="职业" 
                back={(v)=>setValue({...value,value:v})} />}
        {type==='education' && 
            <EducationEditor value={value.value} 
                back={(v)=>setValue({...value,value:v})} />}

        {type==='major' && 
            <BaseEditor value={value.value} 
                title="专业"
                ph="专业" 
                back={(v)=>setValue({...value,value:v})} />}
        {type==='detail' && 
            <DetailEditor value={value.value} 
                hide={value.hide}
                back={(v)=>setValue(v)} />}

    </div>



    function submit() {
        if(!check()){
            return
        }
        axios.put("/api/square/profile",{
            key:type,
            value:value.value,
            hide:value.hide
        }).then(({data})=>{
            if(data.code === 200){
                props.over()
            }else if(data.code === 201){
                props.over()
            }
        }).catch(err=>{
            console.log(err)
        })
    }


    function check(){
        if(type==="name"){
            if(value.value===null || value.value===''){
                Msg.msg("姓名不能为空")
                return false
            }
            //两个字以上 20字以下
            if(value.value.length<2 || value.value.length>20){
                Msg.msg("姓名长度为2-20个字符")
                return false
            }
        }
        if(type==='gender'&&(value.value!=='男'&&value.value!=='女')){
            Msg.msg("性别只能是男或女")
            return false
        }
        if(type==='age'&&!/^\d{2}$/.test(value.value)){
            Msg.msg("年龄只能是两位15-99之间的纯数字")
            return false
        }
        if(type==='age'){
            if(value.value<15 || value.value>99){
                Msg.msg("年龄只能是15-99之间的数字")
                return false
            }
        }
        if(type==='birthday' && (checkDate(value.value)===false)){
            Msg.msg("请填写出生年-月-日的格式")
            return false
        }
        return true
    }
}