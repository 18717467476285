import { Route, Routes } from "react-router-dom";
import Setting from "./index.jsx";
import About from "./about";
import PwdChange from "./pwd-change";
import Help from "./help/index.jsx";
import Roules from "./rules/index.jsx";
import { FontSizeSet } from "./fontsize/index.jsx";
import Security from "./security/index.jsx";
import { Privacy } from "./privacy/index.jsx";



export default function SettingBase(){
    return <Routes>
        <Route path="/" element={<Setting />} />

        <Route path="pwd" element={<PwdChange />} />
        <Route path="about" element={<About />} />
        <Route path="security" element={<Security />} />

        <Route path="help" element={<Help />} />
        <Route path="rules" element={<Roules />} />
        <Route path="fontsize" element={<FontSizeSet />} />
        <Route path="privacy" element={<Privacy />} />
    </Routes>
}