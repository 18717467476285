import { Input } from "antd"
import { useState } from "react"

import style from "./css/birthday.module.css"
import { Hider } from "./hider"

export default function BirthdayEditor(props){

    const [data,setData] = useState({
        value:props.value,
        hide:props.hide
    })

    return<div className={style.container}>
        <div>
            <Input 
                style={{height:'3em',fontSize:'1.5em'}}
                value={data.value} 
                placeholder="YYYY-MM-DD，如：2000-01-01" onChange={e=>{
                    let tmp = {...data,value:e.target.value}
                    setData(tmp)
                    props.back(tmp)
                }} />
        </div>
        <div className={style.hint}>
            格式：YYYY-MM-DD，如：2000-01-01
        </div>
        <Hider value={data.hide} onChange={v=>{
            setData({...data,hide:v})
            props.back({...data,hide:v})
        }} />
    </div>
}