


import { useEffect, useState } from "react"
import style from "./index.module.css"
import { axios } from "../../axios"
import { localDate } from "../../tools"
import { Pagination } from "antd"
import { useNavigate } from "react-router-dom"
export default function Notice(){
    const navi = useNavigate()

    const [notices,setNotices] = useState({
        list:[],
        total:0,
        page:1
    })

    useEffect(()=>{
        getNotices()
        // eslint-disable-next-line
    },[])


    return<div className={style.container}>

        <div>
            <h3>通知</h3>
        </div>

        <div className={style.topBtns}>
            {notices.total>0&&<p>
                <span onClick={()=>allRead()}>全部已读</span>
                <span style={{color:'darkred'}} onClick={()=>delAllRead()}>清除已读</span>
            </p>}
        </div>

        <div className={style.items}>
            {notices.list.map((item,index)=>{
                return <div key={index} 
                        className={[style.item,item.status===1?style.read:""].join(" ")}
                        onClick={()=>{
                            if(item.status===0){
                                setRead(item.id)
                            }
                            naviTo(item.type,item.targetId)
                        }}
                    >
                    <div className={style.content}>{item.content}</div>
                    <div className={style.date}>{localDate(item.dateTime)}</div>
                </div>
            })}

            {notices.total>18&&<div>
                <Pagination 
                    current={notices.page}
                    pageSize={18}
                    total={notices.total}
                    onChange={(page)=>getNotices(page)}
                />    
            </div>}
        </div>
    </div>

    function getNotices(pageTmp) {
        axios.get("/api/notice",{
            params:{
                page:pageTmp?(pageTmp-1):(notices.page-1)
            }
        }).then(({data})=>{
            if(data.code===200){
                setNotices({
                    list:data.data.list,
                    total:data.data.total,
                    page:pageTmp?pageTmp:notices.page
                })
            }
        }).catch(err=>{})
    }

    function setRead(id){
        axios.put("/api/notice/set-read",{
            id
        }).then(({data})=>{
            if(data.code===200){
                getNotices()
            }
        }).catch(err=>{})
    }

    function allRead(){
        axios.put("/api/notice/set-read-all").then(({data})=>{
            if(data.code===200){
                getNotices()
            }
        }).catch(err=>{})
    }

    function delAllRead(){
        axios.delete("/api/notice/delete-read").then(({data})=>{
            if(data.code===200){
                getNotices()
            }
        }).catch(err=>{})
    }

    function naviTo(type,id){
       switch(type){
        case 1:
            navi("/topic/post/detail/"+id)
            break
        case 2:
            navi("/topic/post/reply/"+id)
            break
        case 3:
            navi("/topic/post/reply/"+id)
            break
        case 4:
            navi("/topic/post/reply/"+id)
            break
        case 5:
            navi("/flash/detail/"+id)
            break
        case 6:
            navi("/flash/detail/"+id)
            break
        case 7:
            navi("/flash/detail/"+id)
            break
        case 8:
            navi("/flash/detail/"+id)
            break
        case 9:
            navi("/square/paired/"+id)
            break
        case 10:
            navi("/flash/detail/"+id)
            break
        case 11:
            navi("/topic/post/reply/"+id)
            break
        default:
            break
       } 
    }
}