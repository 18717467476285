import React, { useEffect, useLayoutEffect, useRef,  } from "react"
import { useNavigate } from "react-router-dom"
import { AvatarBaseURL, CACHE, localDate, toFold } from "../../tools"

import style from "./home.module.css"
import Msg from "../my-com/Msg"
import Page from "../my-com/page"
export default function FlashHome(props){

    const navigate = useNavigate()

    const scrollRef = useRef(null)
    useLayoutEffect(()=>{
        const tmp = scrollRef.current
        return ()=>{
            //离开时获取滚动条位置
            if(tmp){
                props.setFlashScroll(tmp.scrollTop)
                console.log("离开时获取滚动条位置",tmp.scrollTop)
            }
        }
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(scrollRef.current){
            let container = scrollRef.current
            container.scrollTop = props.flashCroll
        }
        // eslint-disable-next-line
    },[props.flashScroll,props.flashs])

    return<div className={style.container} ref={scrollRef}>
        <div>
            <div className={style.header}>
                <div>
                    <span className={style.dingwei} onClick={()=>navigate("/locate")}>
                        <svg viewBox="64 64 896 896" focusable="false" data-icon="expand" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs><style></style></defs><path d="M342 88H120c-17.7 0-32 14.3-32 32v224c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V168h174c8.8 0 16-7.2 16-16v-48c0-8.8-7.2-16-16-16zm578 576h-48c-8.8 0-16 7.2-16 16v176H682c-8.8 0-16 7.2-16 16v48c0 8.8 7.2 16 16 16h222c17.7 0 32-14.3 32-32V680c0-8.8-7.2-16-16-16zM342 856H168V680c0-8.8-7.2-16-16-16h-48c-8.8 0-16 7.2-16 16v224c0 17.7 14.3 32 32 32h222c8.8 0 16-7.2 16-16v-48c0-8.8-7.2-16-16-16zM904 88H682c-8.8 0-16 7.2-16 16v48c0 8.8 7.2 16 16 16h174v176c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V120c0-17.7-14.3-32-32-32z"></path></svg>
                    </span>
                    <span className={style.shuaxin} onClick={()=>{
                        props.setFlashScroll(0)
                        new CACHE("flashes").clear().then(e=>{
                            Msg.msg("正在刷新",3000)
                            props.getFlashs(1)
                        })

                    }}>
                        <svg viewBox="64 64 896 896" focusable="false" data-icon="reload" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.1 209.3l-56.4 44.1C775.8 155.1 656.2 92 521.9 92 290 92 102.3 279.5 102 511.5 101.7 743.7 289.8 932 521.9 932c181.3 0 335.8-115 394.6-276.1 1.5-4.2-.7-8.9-4.9-10.3l-56.7-19.5a8 8 0 00-10.1 4.8c-1.8 5-3.8 10-5.9 14.9-17.3 41-42.1 77.8-73.7 109.4A344.77 344.77 0 01655.9 829c-42.3 17.9-87.4 27-133.8 27-46.5 0-91.5-9.1-133.8-27A341.5 341.5 0 01279 755.2a342.16 342.16 0 01-73.7-109.4c-17.9-42.4-27-87.4-27-133.9s9.1-91.5 27-133.9c17.3-41 42.1-77.8 73.7-109.4 31.6-31.6 68.4-56.4 109.3-73.8 42.3-17.9 87.4-27 133.8-27 46.5 0 91.5 9.1 133.8 27a341.5 341.5 0 01109.3 73.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 003 14.1l175.6 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c-.1-6.6-7.8-10.3-13-6.2z"></path></svg>
                    </span>
                    <span className={style.add} onClick={()=>navigate("/flash/add")}>
                        <svg viewBox="64 64 896 896" focusable="false" data-icon="plus-square" width="30px" height="30px" fill="currentColor" aria-hidden="true"><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM704 536c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"></path></svg>
                    </span>
                </div>
            </div>
            <div className={style.contentContainer}>
                {props.flashs.map(e=><div key={e.id} className={[style.item,e.state===1?style.privateItem:''].join(" ")}
                    onClick={()=>{navigate("/flash/detail/"+e.id)}}>
                    <div className={style.itemHeader}>
                        <span className={style.avatar}><img src={AvatarBaseURL+(e.user.avatar?e.user.avatar:'avatar/default/1.png')} alt='avatar'/></span>
                        <span className={style.user}>{getNicknameOrUsername(e.user)}</span>
                        <span className={style.date}>{localDate(e.createDatetime)}</span>
                    </div>
                    <div>
                        <p className={toFold(e.content)?style.hideContent:style.content}>{e.content}</p>
                        {toFold(e.content)&&<p className={style.folder} style={{fontSize:"0.9em",color:"#ababab",padding:"0"}}>查看全部</p>}
                    </div>
                    <p className={style.footer}>
                        <span>💬{e.commentCount}</span>
                        <span>😋{e.up}</span>
                        <span>🥵{e.down}</span>
                    </p>
                </div>)}

                <div style={{padding:'1em 0'}}>
                    <Page 
                        total={props.total}
                        current={props.page}
                        pageSize={18}
                        onChange={p=>{
                            props.setFlashScroll(0)
                            navigate("/flash/list/"+p)
                        }}
                    />
                </div>
            </div>
        </div>
     
    </div>


    function getNicknameOrUsername(user){
        if(user.nickname){
            return user.nickname
        }
        if(user.username){
            return user.username
        }
        return ""
    }
    
}
