


// 订单列表

import { useEffect, useState } from 'react'
import { localDate } from '../../../tools'
import style from './list.module.css'
import { axios } from '../../../axios'
export function OrderList(){

    const [orders,setOrders] = useState([])

    useEffect(()=>{
        getOrderList()
    },[])

    return <div className={style.container}>
        <h3>订单列表</h3>
        <div>
            <div className={style.header}>
                <div>增加天数</div>
                <div>金额</div>
                <div>时间</div>
                <div>状态</div>
            </div>
            {orders.map((order,index)=><div key={index} className={style.order}>
                <div>{order.days}</div>
                <div>{order.money}</div>
                <div>{localDate(order.dateTime)}</div>
                <div>{strStatus(order.status)}</div>
            </div>)}
        </div>
        <div className={style.hint}>
            <span>只展示最新50条</span>
        </div>
    </div>

    function getOrderList() {
        axios.get("/api/pay/order/list-of-user",{
        }).then(({data})=>{
            if(data.code===200){
                setOrders(data.data)
            }
        })
    }
}


function strStatus(v){
    if(v===0){
        return "待支付"
    }else if(v===1){
        return "已支付"
    }else if(v===2){
        return "已取消"
    }else if(v===3){
        return "已完成"
    }
}