import { useContext, useEffect, useState } from "react";
import { PAIRCONTEXT } from "../../context";

import style from "./index.module.css"
import { axios } from "../../../../../axios";
import { Dislike, Like } from "./like";
import { LeftSquareOutlined, RightSquareOutlined } from "@ant-design/icons";
import { localDate } from "../../../../../tools";

export default function ConfirmFace(props){


    const PAIRC = useContext(PAIRCONTEXT)
    const sessionId = PAIRC.flow.pairSessionId

    const [show,setShow] = useState({
        show:false,
        type:'',//like or dislike,
    })

    const [confirmRecord,setConfirmRecord] = useState({
        // sessionFinished
    })

    useEffect(()=>{
        getConfirmRecord()
        // eslint-disable-next-line
    },[])

    return <div>

        <div>
            <h2 style={{textAlign:"center"}}>形象反馈</h2>
        </div>

        <div className={style.hint}>
            <div className={style.a}>
                <p>双方资料都已经确认过了,现在联系方式双方互相可见。</p>
                <p>请及时添加联系方式，互发照片、视频、线下见面的形式确认对方是不是你喜欢的类型。</p>
                <p>确认后请及时来这里反馈，以进入下一个流程。</p>
            </div>
            
            <div className={style.b}>
                <p>每个人喜欢的类型不一样有人喜欢瘦的，有人喜欢胖的，不合适不代表你不好。</p>
                <p>另外，男生是很不上镜的。往往现实中比照片视频好看太多。尽量线下见一面，给自己个机会，也给对方个机会。</p>
                <p>颜值只是开始，人品才是长久相伴的关键。</p>
            </div>
        </div>




        {show.show&&<div>
            {show.type==='like'&&<Like sessionId={sessionId} close={(v)=>{
                setShow({show:false,type:''})
                if(v){
                    getConfirmRecord()
                }
            }} />}   

            {show.type==='dislike' && <Dislike sessionId={sessionId} close={(v)=>{
                setShow({show:false,type:''})
                if(v){
                    getConfirmRecord()
                }
            }} />}
        </div>}

        
        <div className={style.decision}>
            {confirmRecord.mySeeFace && <div>
                <p>
                    <span className={style.btn}>我的决定:</span>&nbsp;
                    {confirmRecord.mySeeFace.toNext?<span>继续接触</span>:<span>不再继续</span>}
                </p>
                <div>
                    {confirmRecord.mySeeFace.comment}
                    <p className={style.date}>{localDate(confirmRecord.mySeeFace.createDatetime)}</p>
                </div>
            </div>}

            {confirmRecord.hisSeeFace && <div>
                <p><span className={style.btn}>他的决定:</span>&nbsp;{confirmRecord.hisSeeFace.toNext?<span>继续接触</span>:<span>停止接触</span>}</p>
                <div>
                    {confirmRecord.hisSeeFace.comment}
                    <p className={style.date}>{localDate(confirmRecord.hisSeeFace.createDatetime)}</p>
                </div>
            </div>}
        </div>


        <div className={style.confirmBtns}>
            {!confirmRecord.mySeeFace && !confirmRecord.sessionFinished && <div>
                <span onClick={()=>setShow({show:true,type:'dislike'})}>不合适</span>
                <span onClick={()=>setShow({show:true,type:'like'})}>继续沟通</span>
            </div>}
        </div>


        <div className={style.preOrNext}>
            <span className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"SAY_HELLO"})}>
                <LeftSquareOutlined />
            </span>
            {PAIRC.canToNext('FACE_PAIRING') && 
                <span  className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"READY_BE_LOVER"})}>
                    <RightSquareOutlined />
                </span>}
        </div>
    </div>


    function getConfirmRecord(){
        axios.get("/api/square/flow/face",{
            params:{
                sessionId
            }
        }).then(({data})=>{
            if(data.code===200){
                setConfirmRecord(data.data)
                let sessionStatus = data.data.sessionStatus
                PAIRC.setFlow({
                    ...PAIRC.flow,
                    status:sessionStatus,
                })
            }
        }).catch(err=>{console.log(err)})
    }
}