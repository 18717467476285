

// 未登陆显示的页面


import { useNavigate } from 'react-router-dom'
import style from './index.module.css'
import { AlertFilled, BellFilled, BulbFilled, CustomerServiceFilled, NotificationFilled, SettingFilled } from '@ant-design/icons'
export function UserUnlogged(){
    const navi = useNavigate()

    return<div className={style.container}>
        <div className={style.top}>
            <div className={style.username} onClick={()=>navi("/login")}>
                请登陆
            </div>
        </div>

        <div className={style.more}>
            <div>
                <span>
                <NotificationFilled style={{color:'chocolate'}} />&nbsp;&nbsp;通知
                </span>
            </div>
            <div>
                <span><SettingFilled style={{color:'#2196F3'}} />&nbsp;&nbsp;设置</span>
            </div>
            <div>
                <span><CustomerServiceFilled  style={{color:'darkred'}}/>&nbsp;&nbsp;联系客服</span>
            </div>
            <div onClick={()=>navi("/setting/rules")}>
                <span><BulbFilled  style={{color:'#ff9800'}}/>&nbsp;&nbsp;社区规则</span>
            </div>
            <div onClick={()=>navi("/setting/about")}>
                <span><AlertFilled style={{color:'green'}} />&nbsp;&nbsp;关于</span>
            </div>
            <div onClick={()=>navi("/setting/help")}>
                <span><BellFilled style={{color:'#F44336'}} />&nbsp;&nbsp;帮助•必读</span>
            </div>
        </div>
    </div>
}