



import { useEffect } from "react";
import { axios } from "../../../axios";
import style from "./followers.module.css";
import { useState } from "react";
import { Pagination } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
export function Followers(){
    const navi = useNavigate()

    const [searchParams,setSearchParams] = useSearchParams()
    const tab = searchParams.get('tab')?searchParams.get('tab'):'fans'
    const page = searchParams.get('page')?parseInt(searchParams.get('page')):1

    const [active,setActive] = useState(tab)

    const [fans,setFans] = useState({
        list:[],
        total:0,
        page:page
    })
    const [following,setFollowing] = useState({
        list:[],
        total:0,
        page:page
    })

    useEffect(()=>{
        getMyFans(1)
        getMyFollowing(1)
        // eslint-disable-next-line
    },[])
    return <div className={style.container}>
        <p className={style.tabs}>
            <span
                onClick={()=>{
                    setActive('fans')
                    setSearchParams(pre=>{
                        let newParams = new URLSearchParams(pre)
                        newParams.set("tab",'fans')
                        newParams.set("page",'1')
                        return newParams
                    })
                }} 
                className={active==='fans'?style.active:''}
                >粉丝</span>
            <span 
                onClick={()=>{
                    setActive('following')
                    setSearchParams(pre=>{
                        let newParams = new URLSearchParams(pre)
                        newParams.set("tab",'following')
                        newParams.set("page",'1')
                        return newParams
                    })
                }}
                className={active==='following'?style.active:''}>关注</span>
        </p>

        {active==='fans'&&<div className={style.items}>
            {fans.list.map((item,index)=><div key={index} className={style.item}>
                <span onClick={()=>navi("/user/profile/"+item.id)}>{item.nickname}</span>
            </div>)}   

            <Pagination 
                simple
                total={fans.total}
                pageSize={18}
                current={fans.page}
                onChange={(page)=>{
                    getMyFans(page,true)
                }}
            /> 
        </div>}
        {active==='following'&&<div className={style.items}>
            {following.list.map((item,index)=><div key={index} className={style.item}>
                <span onClick={()=>navi("/user/profile/"+item.id)}>{item.nickname}</span>
            </div>)}    

            <Pagination
                simple
                total={following.total}
                pageSize={18}
                current={following.page}
                onChange={(page)=>{
                    getMyFollowing(page,true)
                }}
            />
        </div>}
    </div>

    function getMyFans(page,changeURL) {
        axios.get("/api/follower/fans",{
            params:{
                page:page-1
            }
        }).then(({data})=>{
            if(data.code === 200){
                setFans({
                    ...fans,
                    list:data.data.list,
                    total:data.data.total,
                    page:page
                })
                if(changeURL){
                    setSearchParams(pre=>{
                        let newParams = new URLSearchParams(pre)
                        newParams.set("page",page)
                        return newParams
                    })
                }
            }
        })
    }

    function getMyFollowing(page,changeURL){
        axios.get("/api/follower/following",{
            params:{
                page:page-1
            }
        }).then(({data})=>{
            if(data.code === 200){
                setFollowing({
                    ...following,
                    list:data.data.list,
                    total:data.data.total,
                    page:page
                })
                if(changeURL){
                    setSearchParams(pre=>{
                        let newParams = new URLSearchParams(pre)
                        newParams.set("page",page)
                        return newParams
                    })
                }
                
            }
        })
    }
}