
//成员资格


import { useEffect, useState } from "react"
import style from "./index.module.css"
import { CloseSquareFilled } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { axios } from "../../axios"
import { localDate } from "../../tools"
import { Pay } from "./pay"
export default function VIP(){
    const navi = useNavigate()

    const [showMsg,] = useState(false)
    const [vipStatus,setVipStatus] = useState({
        nickname:'',
        vip:null,
        vipHadDays:0,
        vipRestDays:0,
        vipEndDate:'',
        hint:''
    })

    const [paying,setPaying] = useState({
        show:false,
        money:0
    })


    useEffect(()=>{
        getVIPStatus()
        // eslint-disable-next-line
    },[])

    return<div className={style.container}>
        {vipStatus.vip===null?<div></div>:<>
        <div className={style.head}>
            <p>
                <CloseSquareFilled onClick={()=>navi('/user')}/>
            </p>
        </div>

        <div className={style.userInfo}>
            <div>
                <span style={{fontSize:'0.9em',color:'gray'}}>你好，</span>{vipStatus.nickname}
            </div>
            {vipStatus.vip&&<p>
                你的成员卡已有<span>{vipStatus.vipHadDays}</span>天,
                剩余<span>{vipStatus.vipRestDays}</span>天，
                至<span>{localDate(vipStatus.vipEndDate)}</span>。
            </p>}
            {!vipStatus.vip&&<p>
                你的成员卡已到期，请及时增加天数。如果你有任何困难，请联系我们获取免费成员卡和其他帮助。   
            </p>}
        </div>

        <div className={style.recharge}>
            <div className={style.first}>
                <span 
                    onClick={()=>setPaying({show:true,money:12,type:1})} 
                    className={style.btn}>增加<span style={{fontSize:'1.2em',fontWeight:'bold'}}>30天</span>/<sub>12rmb</sub></span>
                <span 
                    onClick={()=>setPaying({show:true,money:120,type:2})} 
                    className={style.btn}>增加<span style={{fontSize:'1.2em',fontWeight:'bold'}}>360天</span>/<sub>120rmb</sub></span>
            </div>
            <div className={style.second}>
                <p>如果你是学生、失业、老、病、残等弱势人士，<span>请点击这里</span></p>
                <span
                    onClick={()=>setPaying({show:true,money:100,type:3})} 
                    className={style.btn}>增加<span style={{fontSize:'1.2em',fontWeight:'bold'}}>360天</span>
                    <sub>/100rmb</sub>
                </span>
            </div>
        </div>
        
        <div className={style.love}>
            如果你目前却有困难，请联系客服。
            <div>我们真诚的愿意给你力所能及的帮助。</div>
            <div>love is everything~ </div>
        </div>
        <div className={style.hint}>
            {/* 内测中,使用兑换码 suspar 可兑换40天成员卡,每月限兑换一次 */}
            {vipStatus.hint}
        </div>

        <div className={style.bottom}>
            <p>
                <span onClick={()=>navi("/user/vip/code")}>使用兑换码</span>
                <span onClick={()=>navi("/user/vip/change-record")}>成员卡记录</span>
                <span onClick={()=>navi("/user/vip/order-list")}>所有订单</span>
            </p>
        </div>

        {showMsg&&<div>
            浮尘是一个封闭式社区，希望将主要用户维持在高知群体范畴内。优质的用户才能产生优质的内容，优质的内容才能吸引更多优质的用户。
            只有这样的良性循环，才能让社区长久发展。
            在营收上我们曾在广告贴片和会员制之间犹豫，最终选择了会员制，因为我们认为这是更加可持续的方式。
            广告贴片会让用户体验变差，不符合我们做一个”纯净“”真诚“社区的理念
            会员机制能够过滤掉大量的垃圾用户，减少反同人士滋扰，增加创建恶意帐号、小号的成本，客观上避免垃圾内容的泛滥。
            社区运营初期，没有额外营收的话很难支撑下去，
            目前我们的创业资金已经所剩无几，开发运营人员苦苦坚持中，
            如果您认可浮尘社区的理念且您力有所及，欢迎通过捐赠或者多充值成员卡天数给予我们一些支持，感谢，再次感谢。
        </div>}

        {paying.show&&<>
            <Pay
                type={paying.type} 
                money={paying.money} close={()=>setPaying({
                show:false,
                money:0
            })} />
        </>}

        </>}
    </div>
    


    function getVIPStatus() {
       axios.get("/api/vip/status",{

       }).then(({data})=>{
        if(data.code===200){
            setVipStatus(data.data)
        }
       }).catch(err=>{
           console.log(err)
       })
    }

}


