import { Input } from "antd"
import { useState } from "react"

import { Hider } from "./hider"

export default function HometownEditor(props){


    const [value,setValue] = useState({
        value:props.value===null?'':props.value,
        hide:props.hide
    })

    return<div>
        <h4>设置家乡</h4>
        <div>
            <Input value={value.value} 
                placeholder='家乡' onChange={e=>{
                    let tmp = {...value,value:e.target.value}
                    setValue(tmp)
                    props.back(tmp)
                }} />
        </div>

        <Hider value={value.hide} onChange={v=>{
            setValue({...value,hide:v})
            props.back({...value,hide:v})
        }} />
    </div>
}