

import { useState } from "react"
import { axios } from "../../../axios"
import { useNavigate } from "react-router-dom"


import style from "./passkey.module.css"
import { Button } from "antd"
import { CloseSquareFilled } from "@ant-design/icons"

function Passkey(props) {

    const navi = useNavigate()

    const [passkey,setPasskey] = useState("")
    const [errorMsg,setErrorMsg] = useState("")

  return <div className={style.containerBg}>
        <div className={style.container}>

            <div className={style.closeBg}>
                <p>
                    <span onClick={()=>navi("/square")}><CloseSquareFilled/></span>
                </p>
            </div>

            <div className={style.inputBg}>
                <div>
                    <input
                        defaultValue={""} 
                        placeholder="粘贴他人的访问密钥" 
                        onChange={v=>setPasskey(v.target.value)} />
                </div>
                <div style={{marginTop:'1em'}}>
                    <Button type="dashed" onClick={getProfileByPasskey}>查看</Button>
                </div>
            </div>

            <div>
                {errorMsg&&<p>{errorMsg}</p>}
            </div>
        </div>
  </div>


    function getProfileByPasskey() {
        if(!passkey){
            return
        }
        axios.get("/api/square/share/profile/by-passkey",{
            params:{
                passkey
            }
        }).then(({data})=>{
            if(data.code === 200){
                //如果是自己再跳转到预览 否则直接进入会话
                let id = data.data
                navi("/square/paired?id="+id)
            }else{
                let msg = data.msg
                setErrorMsg(msg)
                setTimeout(()=>{
                    setErrorMsg("")
                },2000)
                
            }
        }).catch(err=>console.log(err))
    }
}
export  {Passkey}