import { useEffect, useState } from "react"
import { axios } from "../../../axios"

import { genderStr, getSexRole } from "./dict"
import Editors from "./editors"
import { useNavigate } from "react-router-dom"

import style from "./index.module.css"
import Msg from "../../my-com/Msg"
export default function SquareProfile(props) {

    const navi = useNavigate()

    useEffect(() => {
        getProfile()
        window.history.scrollRestoration = 'manual'
        return () => {
            window.history.scrollRestoration = 'manual'
        }
        // eslint-disable-next-line
    }, [])

    const [edit, setEdit] = useState({
        show: false,
        key: "",
        title: "",
        value: null,
        placeholder: ""
    })
    const [profile, setProfile] = useState({

    })
    const [checkProfile, setCheckProfile] = useState({
        age: false,
        contact: false,
        name: false,
        province: false,
        sexRole: false,
    })


    return <div className={style.container}>
        {!edit.show && <div className={style.profile}>
            <h3>我的资料</h3>
            <div className={style.header}>
                <b style={{fontSize:'14px'}}>
                    <span className={style.gray}>灰色</span>条目确认资料后才会释放可见
                </b>
            </div>
            <div className={style.btns}>
                <span className={style.btn} onClick={()=>navi("/square/profile/example")}>示例</span>
                <span className={style.btn} onClick={()=>navi("/square/share")}>分享</span>
                <span className={style.btn} onClick={() => navi("/square/profile/preview")}>预览</span>
            </div>
            <div className={style.hint}>
                <p>置灰隐藏内容将在双方进入下一步后释放可见，未匹配前内容在数据层面被隐藏，安全、隐私。</p>
                <p>你可以使用密钥分享功能与他人互换基本资料进入匹配流程。</p>
            </div>


            <div className={[style.item, checkProfile.name ? style.error : ""].join(" ")}
                onClick={() => toEdit("name", profile.name)}>
                <span>昵称</span>
                <div>{profile.name}</div>
            </div>

            <div className={style.item}
                onClick={() => toEdit("gender", genderStr(profile.gender))}>
                <span>性别</span>
                <div>{genderStr(profile.gender)}</div>
            </div>

            <div className={[style.item, checkProfile.age ? style.error : ''].join(" ")} onClick={() => toEdit("age", profile.age)}>
                <span>年龄</span>
                <div>{profile.age}</div>
            </div>

            <div className={[style.item, (profile.hideCode & (1 << 17)) !== 0 ? style.hide : ""].join(" ")}
                onClick={() => toEdit("birthday", profile.birthday)}>
                <span>生日</span>
                <div>{profile.birthday}</div>
            </div>

            <div className={style.signature}
                onClick={() => toEdit("signature", profile.signature)}>
                <span>个性签名</span>
                <div>{profile.signature}</div>
            </div>

            <div className={[style.item, (profile.hideCode & (1 << 14)) !== 0 ? style.hide : ""].join(" ")}
                onClick={() => toEdit("sexRole", profile.sexRole)}>
                <span>性偏向</span>
                <div>{getSexRole(profile.sexRole)}</div>
            </div>

            <div className={style.item}
                onClick={() => toEdit("height", profile.height)}>
                <span>身高</span>
                <div>{profile.height}</div>
            </div>
            <div className={style.item}
                onClick={() => toEdit("weight", profile.weight)}>
                <span>体重</span>
                <div>{profile.weight}</div>
            </div>
            <div className={[style.item, checkProfile.province ? style.error : ""].join(" ")}
                onClick={() => toEdit("province", {
                    province: profile.province,
                    city: profile.city
                })}>
                <span>所在城市</span>
                <div>
                    {profile.province ? profile.province : ''} {profile.city ? profile.city : ''}
                </div>
            </div>
            <div className={[style.item, (profile.hideCode & (1 << 10)) !== 0 ? style.hide : ""].join(" ")}
                onClick={() => toEdit("hometown", profile.hometown)}>
                <span>家乡</span>
                <div>{profile.hometown}</div>
            </div>

            <div className={style.item}
                onClick={() => toEdit("job", profile.job)}>
                <span>职业</span>
                <div>{profile.job}</div>
            </div>
            <div className={[style.item, (profile.hideCode & (1 << 8)) !== 0 ? style.hide : ""].join(" ")}
                onClick={() => toEdit("salary", profile.salary)}>
                <span>年薪</span>
                <div>{profile.salary}</div>
            </div>
            <div className={style.item}
                onClick={() => toEdit("education", profile.education)}>
                <span>学历</span>
                <div>{profile.education}</div>
            </div>

            <div className={[style.item, (profile.hideCode & (1 << 6)) !== 0 ? style.hide : ""].join(" ")}
                onClick={() => toEdit("school", profile.school)}>
                <span>学校</span>
                <div>{profile.school}</div>
            </div>

            <div className={style.item}
                onClick={() => toEdit("major", profile.major)}>
                <span>专业</span>
                <div>{profile.major}</div>
            </div>
            <div className={[style.detail, (profile.hideCode & (1 << 4)) !== 0 ? style.hide : ""].join(" ")} onClick={() => toEdit("detail", profile.detail)}>
                <span>详细描述</span>
                <div>
                    {profile.detail}
                </div>
            </div>
            <div className={[style.item, style.hide].join(" ")}
                onClick={() => toEdit("wechat", profile.wechat)}>
                <span>微信</span>
                <div>{profile.wechat}</div>
            </div>
            <div className={[style.item, style.hide, checkProfile.contact ? style.error : ''].join(" ")}
                onClick={() => toEdit("contact", profile.contact)}>
                <span>联系方式</span>
                <div>{profile.contact}</div>
            </div>
        </div>}
        {edit.show && <div className={style.edit}>
            <Editors type={edit.key}
                hideCode={profile.hideCode}
                value={edit.value}
                over={() => { getProfile(true); setEdit({ ...edit, show: false }) }}
                close={() => setEdit({ ...edit, show: false })} />
        </div>}
    </div>


    function toEdit(key, v) {
        let title = key
        let placeholder = ""
        let des = ""
        switch (title) {
            case "height":
                title = "身高"
                break;
            case "weight":
                title = "体重"
                break;
            case "province":
                title = "所在城市"
                break;
            case "hometown":
                title = "家乡"
                break;
            case "job":
                title = "职业"
                break;
            case "salary":
                title = "年薪"
                break;
            case "education":
                title = "学历"
                des = "本科在读，本科毕业，本科肄业，研究生在读，研究生毕业，博士在读，博士毕业"
                break;
            case "school":
                title = "学校"
                break;
            case "major":
                title = "专业"
                break;
            case "detail":
                title = "详细描述"
                break;
            case "wechat":
                title = "微信"
                break;
            default:
                ;
        }
        setEdit({
            ...edit, show: true, key, title,
            ...(v !== undefined && { value: v }),
            placeholder,
            ...(des && { des })
        })
    }

    function getProfile() {
        axios.get("/api/square/profile").then(({ data }) => {
            if (data.code === 200) {
                setProfile(data.data)
                check(data.data)
            } else if (data.code === 201) {
                setProfile(data.data)
                check(data.data)
            } else {
                Msg.msg(data.msg)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    function check(profile) {
        const tmp = {
            age: false,
            contact: false,
            name: false,
            province: false,
            sexRole: false
        }

        //检查昵称，在2-20个字符
        if(!profile.name){
            tmp.name = true
        }else if (profile.name.length < 2 || profile.name.length > 20) {
            tmp.name = true
        }
        //检查age 值在15-100
        if (profile.age < 15 || profile.age > 99) {
            tmp.age = true
        }
        //sexRole不能为空
        if (profile.sexRole === null) {
            tmp.sexRole = true
        }
        //province不能为空
        if (profile.province === null) {
            tmp.province = true
        }
        //联系方式不能为空
        if (profile.contact === null) {
            tmp.contact = true
        }
        setCheckProfile(tmp)
    }
}

