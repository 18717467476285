import { useState } from "react"
import style from "./css/education.module.css"

export default function EducationEditor(props){

    const [value,setValue] = useState(props.value==null?'其他':props.value)
    const list = ['本科在读','本科','硕士在读',
        '硕士','博士在读','博士','其他']
        
    return<div>
        <div>
            <h4>修改学历</h4>
        </div>
        <div className={style.items}>
            {list.map(e=><div key={e} className={e===value?style.active:""}
                onClick={()=>{
                    setValue(e)
                    props.back(e)
                }}>
                {e}
            </div>)}
        </div>
    </div>

}