import style from "./index.module.css"
import { useNavigate } from "react-router-dom"

export default function Home({enable,loadding}){

    const navi = useNavigate()

    return<div className={style.home}>
        <div className={style.container}>

        <div className={style.top} >
            <div className={style.hint}>
                <p>浮尘于世，总要有个归宿。希望你能在这里找到你的归宿。love is everything~</p>
            </div>
        </div>

        <div className={style.itemsBg}>
            <div className={[style.items,loadding?style.loadding:''].join(" ")}>
                <div className={style.left}> 
                    <div className={style.find} onClick={()=>{
                        if(enable)navi("/square/find")
                        }}>
                        <div>
                            {enable&&<span>开始寻他</span>}
                        </div>
                    </div>
                    <div className={style.profile} onClick={()=>{
                        if(enable)navi("/square/profile")
                    }}>
                        <div>
                            {enable&&<span>我的资料</span>}
                        </div>
                    </div>
                </div>

                <div className={style.right}>
                    <div className={style.love} onClick={()=>{
                        navi("/square/lover")
                    }}>
                        <div >
                            <span>我的爱人</span>
                        </div>
                        
                        <div className={style.heart}></div>
                    </div>


                    <div className={style.record} onClick={()=>{
                        if(enable)navi("/square/paired")
                    }}>
                        <div>
                            <p>{enable&&<span>擦肩记录</span>}</p>
                            <div>
                                别错过，别等太久
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className={style.bottom}>
            <div onClick={()=>{
                        if(enable)navi('/square/share')
                    }}>
                我的密钥
            </div>

            <div onClick={()=>{
                        if(enable)navi("/square/share/passkey")
                    }}>
                密钥直达
            </div>

            <div onClick={()=>navi("/square/setting")}>
                必读与设置
            </div>
        </div>
        
    </div>
    </div>




}