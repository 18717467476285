import { useContext, useEffect, useState } from "react"
import { axios } from "../../../../axios"
import { PAIRCONTEXT } from "../context"

import style from "./index.module.css"
import { localDate } from "../../../../tools"
import { Pagination } from "antd"
import { CloseSquareFilled } from "@ant-design/icons"


function Finished({close}){

    const PAIRC = useContext(PAIRCONTEXT)
    
    const [records,setRecords] = useState({
        page:1,
        list:[],
        total:0
    })
    useEffect(()=>{
        getProcessingList(1)
        // eslint-disable-next-line
    },[])

    
    return <div className={style.container}>

        <div className={style.header}>
            <span className={style.b} onClick={close}><CloseSquareFilled /></span>
            <span className={style.a}>已结束的流程({records.total})</span>
        </div>
        {
            records.list.map(e=><div key={e.id} className={style.item} onClick={()=>{
                PAIRC.setFlow({pairSessionId:e.id,status:e.status,showStatus:e.status})
            }}>
                <p><span className={style.a}>昵称:</span> <span>{e.name}</span></p>
                <p className={style.statusContainer}><span className={style.b}>状态:</span>&nbsp;  
                    <span className={style.status}>{statusToCN(e.status)}</span>&nbsp;
                    <span className={style.finish}>已结束</span>
                </p>
                <p> 
                    <span className={style.c}>时间:</span>&nbsp;
                    <span className={style.date}>{localDate(e.createDatetime)}</span>
                </p>
                {e.originFrom&&<p><span className={style.d}>来源:</span> <span>{e.originFrom}</span></p>}
                {e.comment&&<p><span className={style.e}>备注:</span> <span className={style.f}>{e.comment}</span></p>}
            </div>)
        }
        {records.total>18&&<div>
            <Pagination
                current={records.page}
                total={records.total}
                pageSize={18}
                onChange={(page)=>{
                    getProcessingList(page)
                }}
            />
        </div>}
    </div>


    function getProcessingList(page){
        axios.get("/api/square/flow/records/finished",{
            params:{
                page:page-1
            }
        }).then(({data})=>{
            if(data.code===200){
                data = data.data
                setRecords({...records,page,total:data.total,list:data.list})
            }
        }).catch(err=>{})

    }

    function statusToCN(status){
        console.log(status)
        if(status === 'CREATED'){
            return "待确认资料"
        }
        if(status === 'SAY_HELLO'){
            return "资料确认中"
        }
        if(status === 'FACE_PAIRING'){
            return "形象反馈中"
        }
        if(status === 'READY_BE_LOVER'){
            return "一周接触"
        }
        if(status === 'NOW_LOVER'){
            return "已确认关系"
        }
        if(status === 'BREAK_UP'){
            return "已分手"
        }
        return ""
    }
}

export {Finished}