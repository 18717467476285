import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { CACHE } from "../../tools"
import { axios } from "../../axios"
import FlashHome from "./home"


export default function FlashWarrper({root,flashCroll,setFlashScroll}){
    const pageFromURL = useParams().page

    let page = root?1:parseInt(pageFromURL)

    const [flashs,setFlashs] = useState({
        page:page,
        total:0,
        list:[]
    })

    useEffect(()=>{
        getFlashs(page)
        // eslint-disable-next-line
    },[page])

    function getFlashs(n){
        //从缓存获取
        new CACHE("flashes").get(n).then(data=>{
            if(data){
                setFlashs({
                    page:n,
                    total:data.total,
                    list:data.list
                })
            }else{
                axios.get("/api/flash",{
                    params:{
                        page:n-1
                    }
                }).then(({data})=>{
                    if(data.code===200){
                        let tmp = data.data
                        setFlashs({
                            page:n,
                            total:tmp.total,
                            list:tmp.list
                        })
                        new CACHE("flashes").add(n,tmp)
                      
                    }
                }).catch(err=>{
                    console.log(err)
                })
            }
        }).catch(er=>{
            axios.get("/api/flash",{
                params:{
                    page:n-1
                }
            }).then(({data})=>{
                if(data.code===200){
                    let tmp = data.data
                    setFlashs({
                        page:n,
                        total:tmp.total,
                        list:tmp.list
                    })
                    new CACHE("flashes").add(n,tmp)
                }
            }).catch(err=>{
                console.log(err)
            })
        })
    }

    
    return <>
        <FlashHome
            flashCroll={flashCroll}
            setFlashScroll={setFlashScroll}
            page={flashs.page}
            total={flashs.total}  
            getFlashs={(page)=>getFlashs(page)}
            flashs={flashs.list}/>
    </>
}