import { CloseSquareFilled } from "@ant-design/icons";



//定位话题或者回复
//话题可以使用id  或者 链接中提取
//回复只能从链接中提取

import style from "./index.module.css"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { axios } from "../../axios";
export default function LocatePostFlashReply(){
    const navi = useNavigate()


    const [text,setText] = useState("")
    return <div className={style.bg}>
        <div>
            <p className={style.title}>
                <span className={style.a}>定位话题、回复、动态</span>
                <span className={style.b} onClick={()=>navi(-1)}><CloseSquareFilled /></span>
            </p>

            <div className={style.input}>
                <textarea 
                    value={text}
                    placeholder="请填入站内分享链接或者话题id" 
                    onChange={v=>setText(v.target.value)} 
                />
            </div>

            <div>
                <span className={style.locateBtn} onClick={locate}>定位</span>
            </div>
        </div>
    </div>

    function locate() {
        if(text==='' || text===null){
            return
        }
        if(text.trim()===''){
            return
        }
        //如果text是个数字  话题id
        if(!isNaN(text.trim())){
            let tmp = text.trim()
            //是否存在 存在才跳转
            axios.get("/api/topic/post/exist",{
                params:{
                    id:tmp
                }
            }).then(({data})=>{
                if(data.code===200){
                    navi("/topic/post/detail/"+tmp)
                }else{
                    alert("话题不存在")
                }
            }).catch(err=>{})
            return
        }


        //是否是动态分享链接
        /*
        【好累】 http://XXX:3000/flash/detail/112 asdasdas
        */
        let reg = /https:\/\/.*\/flash\/detail\/(\d+)/
        let res = text.match(reg)
        if(res){
            axios.get("/api/flash/detail/exist",{
                params:{
                    id:res[1]
                }
            }).then(({data})=>{
                if(data.code===200){
                    navi("/flash/detail/"+res[1])
                }else{
                    alert(data.msg)
                }
            }).catch(err=>{})
            return
        }

        //话题分享链接
        /*
            【江苏盐城白皮健身男】 http://XXXX:3000/topic/post/detail/182啊四大四大
        */
        reg = /https:\/\/.*\/topic\/post\/detail\/(\d+)/
        res = text.match(reg)
        if(res){
            axios.get("/api/topic/post/exist",{
                params:{
                    id:res[1]
                }
            }).then(({data})=>{
                if(data.code===200){
                    navi("/topic/post/detail/"+res[1])
                }else{
                    alert(data.msg)
                }
            }).catch(err=>{})
            return
        }

        //回复分享链接
        /*
            【@doctorx  test】 http://XXX:3000/topic/post/reply/649 XXX123asdasd
        */
        reg = /https:\/\/.*\/topic\/post\/reply\/(\d+)/
        res = text.match(reg)
        if(res){
            axios.get("/api/topic/reply/detail/exist",{
                params:{
                    id:res[1]
                }
            }).then(({data})=>{
                if(data.code===200){
                    navi("/topic/post/reply/"+res[1])
                }else{
                    alert(data.msg)
                }
            }).catch(err=>{})
            return
        }
    }
    
}