
import style from "./index.module.css"

export default function Roules(){


    return <div className={style.container}>
        <h2>社区(内容)规则</h2>

        <div className={style.rules}>
            <p>
                1. 请勿<span className={style.bold}>人身攻击</span>  包括但不限于辱骂、诽谤、侮辱、挑衅、恐吓、威胁、人肉搜索、暴露隐私等。
            </p>
            <p>
                2. 请勿骚扰、人肉他人资料、通讯工具等。
            </p>
            <p>
                3. 请勿<span className={style.bold}>发布广告</span>。
            </p>
            <p>
                4. 请勿发布<span className={style.bold}>色情内容</span>。 色情内容请到色情社区比如X。
            </p>
            <p>
                5. 请勿发布<span className={style.bold}>违法内容</span>,包括但不限于暴力、宗教、反动。
            </p>
            <p>
                6. 请勿发布<span className={style.bold}>政治敏感内容</span> 包括但不限于国家体制、政策、领导人。
            </p>
            <p>
                7. 请勿发布恶意虚假信息。
            </p>
            <p>
                8. 请勿发布大量重复、无意义的<span className={style.bold}>垃圾信息</span>。
            </p>
        </div>


        <div className={style.a}>
            <p>
            这里不讨论言论自由的边界，任何地方都有一套合适的规则才能持续的发展成长。规则以外皆可为，如果对以上规则有疑问欢迎到<b>‘社区建设’</b>节点下新建话题进行讨论。
            </p>
        </div>


        <div className={style.hint}>
            <div>
                <p style={{
                    fontWeight:'bold',
                    textAlign:'center',
                    color:'goldenrod',
                }}>功能介绍</p>
            </div>
            <div>
                <h4 style={{color:'goldenrod'}}>动态</h4>
                <div style={{fontSize:'0.9em',color:'#302c28',textIndent:'2em'}}>
                    <p>
                        发布的动态支持私有模式，此种模式下仅发布者可见。可以用来作为生活日志，笔记等。因为私有模式仅发布者可见，所以动态内容不必遵守任何规则。
                    </p>
                    <p>
                        公开发布的话，将全站可见。请遵守以上我们的社区规范。
                    </p>
                </div>
            </div>

            <div>
                <h4 style={{color:'goldenrod'}}>话题</h4>
                <div  style={{fontSize:'0.9em',color:'#302c28',textIndent:'2em'}}>
                    <p>
                        话题类似与贴吧的帖子，也类似于知乎的提问。为了将话题根据共性归类，划分了一些节点，请将话题发布在正确的节点下。
                    </p>
                    <p>
                        有价值的内容往往不是体现在一个帖子中，而是很多回复更值得收藏。
                    </p>
                    <p>
                        所以我们将回复的权重提高，每个回复都可以被点赞、被收藏，并且回复还可以拥有自己的子回复，这样更利于深入讨论，有价值的回复也更容易被看到。
                    </p>
                    <p>
                        话题、回复内容请符合我们的内容规则，以维持有逻辑、有价值并且友好的讨论环境。
                    </p>
                </div>
            </div>

            <div>
                <h4 style={{color:'goldenrod'}}>寻他</h4>
                <div style={{fontSize:'0.9em',color:'#302c28',textIndent:'2em'}}>
                    <p>寻他流程和规则请到寻他模块查看‘必读与设置’。</p>
                    <p>寻找长久亲密关系是本社区的出发点和基石，浮尘不欢迎约炮、滥交人士，如果你是请你去合适的平台。</p>
                    <p>自尊自爱，爱人爱己。love is everything.</p>
                </div>
            </div>

        </div>
    </div>
}