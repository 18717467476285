import { Input } from "antd"
import { useState } from "react"

import style from "./css/detail.module.css"
import { Hider } from "./hider"
export default function DetailEditor(props){

    const [value,setValue] = useState({
        value:props.value,
        hide:props.hide
    })

    return<div className={style.container}>
        <div>
            <Input.TextArea value={value.value} autoSize={{minRows:6}}
                placeholder="详细介绍 性格，爱好，家庭情况，学业情况，对另一半的期待，对另一半的要求等等" onChange={e=>{
                    setValue({...value,value:e.target.value})
                    props.back({...value,value:e.target.value})
                }} />
        </div>

        <Hider value={value.hide} onChange={(v)=>{
            setValue({...value,hide:v})
            props.back({...value,hide:v})
        }} />
    </div>
}