import { useState } from "react"

import style from "./css/gender.module.css"

export default function GenderEditor(props){

    const [value,setValue] = useState({
        value:props.value,
        hide:props.hide
    })

    return<div>
        <h4>性别</h4>
        <div className={style.items}>
            <div onClick={()=>choice('男')} 
                className={value.value==="男"?style.active:''}>男</div>
            <div onClick={()=>choice('女')}
                className={value.value==="女"?style.active:''}>女</div>
        </div>
    </div>

    function choice(v) {
        let tmp = {...value,value:v}
        setValue(tmp)
        props.back(tmp)
    }
}