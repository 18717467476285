import { useNavigate } from "react-router-dom";
import Btn from "../../my-com/btn";



import style from "./index.module.css"

export default function About(){
    const navi = useNavigate()
    return <div className={style.container}>
        <p className={style.header}>
            <Btn text="返回" onClick={()=>navi("/user")} />
        </p>
        <div className={style.title}>
            <p>人生终归落于平淡，我不喜欢片刻欢愉后空虚袭来的感觉，我想要爱。</p>
        </div>
        <div className={style.goal}>
            <div>社区目标</div>
            <p>致力于做简中<b>最干净、最隐私、最好用的</b>BL交流、成长社区和<b>以长久亲密关系为结果</b>的找对象途径。</p>
        </div>

        <div className={style.foot}>
            <p>如果你也厌倦了or不喜欢 '约?' '看看你?'，欢迎尝试下浮尘(请牢记我们的地址：<b style={{color:'goldenrod'}}>suspar.com</b>)。浮尘于世，总要有个归宿。希望你能在这里找到你的归宿。love is everything~
            </p>
        </div>
    </div>
}