import React from 'react';
import { subscribeNotify, unsubscribeNotify } from './tools';


const Test = () => {

  return<div>

    <button onClick={subscrib}>test</button>
    <button onClick={unsubscribe}>unsubscribe</button>


  </div>
    async function unsubscribe() {
        unsubscribeNotify()
    }

    async function subscrib(){
        subscribeNotify()
    }
}

export default Test;

