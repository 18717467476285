import { Button } from "antd";
import { useNavigate } from "react-router-dom";


import style from "./hint.module.css"
export default function SquareHint() {
    const navi = useNavigate();

    return<div className={style.container}>
        <div>
            <p>祝你幸福，点击</p>
            <div>
                <p><Button type="dashed" onClick={()=>navi("/square")}>去往寻他主页</Button></p>
            </div>
        </div>
    </div>
}