import { Button, Input } from "antd";
import { useContext, useRef, useState } from "react";
import { axios } from "../../axios";
import { useNavigate } from "react-router-dom";
import { Switch } from "antd";

import style from "./add.module.css"
import { CACHE } from "../../tools";
import { SusparContext } from "../../context";
import Msg from "../my-com/Msg";


export default function FlashAdd(){
    const SUSPAR = useContext(SusparContext)

    let tmp = localStorage.getItem("cache_flash_add")

    const [addContent,setAddContent] = useState(tmp?tmp:"")
    const [isPrivate,setIsPrivate] = useState(false)
    const navi = useNavigate()

    const submitingRef = useRef(false)

    return<div className={style.container}>

        <h3>发布动态</h3>
        <p>
            <Switch checked={isPrivate} checkedChildren="仅自己可见" unCheckedChildren="仅自己可见" onChange={(newV)=>{
                setIsPrivate(newV)
            }} />
        </p>

        <Input.TextArea
            maxLength={2000} 
            placeholder="此刻心情，记录一下。"
            value={addContent} 
            defaultValue={""}
            autoSize={{minRows:6}}
            onChange={e=>{
                setAddContent(e.target.value)
                localStorage.setItem("cache_flash_add",e.target.value)
            }}
            />
        <p>
            {!SUSPAR.loggedIn&&<Button onClick={()=>navi("/login")}>请先登陆</Button>}
            {SUSPAR.loggedIn&&<span className={style.btn} onClick={()=>addFlash()}>提交</span>}
        </p>
    </div>

    function addFlash(){
        if(submitingRef.current){
            return
        }
        submitingRef.current = true

        //content不能超过2000字
        if(addContent.length===0){
            Msg.msg("内容不能为空")
            return
        }
        if(addContent.length>2000){
            Msg.msg("内容不能超过2000字")
            return
        }
        axios.post("/api/flash",{
            content:addContent,
            isPrivate:isPrivate
        }).then(({data})=>{
            if(data.code===200){
                new CACHE("flashes").clear().then(e=>{
                    navi("/flash/detail/"+data.data)
                })
                localStorage.removeItem("cache_flash_add")
            }else{
                Msg.msg(data.msg)
            }
            submitingRef.current = false
        }).catch(err=>{
            submitingRef.current = false
            console.log(err)
        })
    }
}

