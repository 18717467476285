


import style from "./index.module.css"
export function ProfileExample(){

    return <div className={style.container}> 
    <div className={style.profile}>
    <h3>资料示例</h3>
    <div className={style.header}>
        <b style={{fontSize:'14px'}}>
            <span className={style.gray}>灰色</span>条目确认资料后才会释放可见
        </b>
    </div>
    <div className={style.hint}>
        <p>置灰隐藏内容将在双方进入下一步后释放可见，未匹配前内容在数据层面被隐藏，安全、隐私。</p>
        <p>你可以使用密钥分享功能与他人互换基本资料进入匹配流程。</p>
    </div>


    <div className={[style.item].join(" ")}>
        <span>昵称</span>
        <div>浮浮沉沉</div>
    </div>

    <div className={style.item}>
        <span>性别</span>
        <div>男</div>
    </div>

    <div className={[style.item].join(" ")}>
        <span>年龄</span>
        <div>34</div>
    </div>

    <div className={[style.item].join(" ")}>
        <span>生日</span>
        <div>1990-09-01</div>
    </div>

    <div className={style.signature}>
        <span>个性签名</span>
        <div>恋爱脑，love is everything</div>
    </div>

    <div className={[style.item].join(" ")}>
        <span>性偏向</span>
        <div>1、0都行</div>
    </div>

    <div className={style.item}>
        <span>身高</span>
        <div>169</div>
    </div>
    <div className={style.item}>
        <span>体重</span>
        <div>60kg</div>
    </div>
    <div className={[style.item].join(" ")}>
        <span>所在城市</span>
        <div>
            江苏省 扬州市
        </div>
    </div>
    <div className={[style.item].join(" ")}>
        <span>家乡</span>
        <div>安徽省 南京市</div>
    </div>

    <div className={style.item}>
        <span>职业</span>
        <div>医生</div>
    </div>
    <div className={[style.item, style.hide].join(" ")}>
        <span>年薪</span>
        <div></div>
    </div>
    <div className={style.item}>
        <span>学历</span>
        <div>硕士研究生</div>
    </div>

    <div className={[style.item].join(" ")}>
        <span>学校</span>
        <div>协和医学院</div>
    </div>

    <div className={style.item}>
        <span>专业</span>
        <div>临床医学</div>
    </div>
    <div className={[style.detail].join(" ")} >
        <span>详细描述</span>
        <div>
            性格，<br/>
            爱好，<br/>
            家庭情况，<br/>
            学业情况，<br/>
            对另一半的期待，<br/>
            等等           
        </div>
    </div>
    <div className={[style.item, style.hide].join(" ")}>
        <span>微信</span>
        <div></div>
    </div>
    <div className={[style.item, style.hide].join(" ")}>
        <span>联系方式</span>
        <div></div>
    </div>
</div>

</div>
}