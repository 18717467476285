import { Input } from "antd"
import { useState } from "react"

import style from "./css/contact.module.css"
export default function ContactEditor(props){


    const [value,setValue] = useState({
        value:props.value,
        hide:true
    })

    return<div className={style.container}>
        <h4>设置联系方式</h4>
        <div>
            <Input value={value.value} 
                placeholder='联系方式' onChange={e=>{
                    let tmp = {...value,value:e.target.value}
                    setValue(tmp)
                    props.back(tmp)
                }} />

            <div className={style.hint}>
                <p>填写在这里的联系方式默认双方都不可见，互相确认基本资料后互相可见。</p>
                <p>为了保护你的隐私、维护良好的匹配规则、避免莫名其妙的人和骚扰等，请不要在签名和详细描述中提供任何联系方式。</p>
            </div>
        </div>
    </div>
}