import style from './index.module.css'
import Confirm from "../my-com/confirm"
import { useNavigate } from "react-router-dom"
import { unsubscribeNotify } from '../../tools'

export default function PhoneSetting(props){

    const navi = useNavigate()


    return <div className={style.container}>
        <p style={{textAlign:"center",fontWeight:"bolder"}}>设置</p>

        <div className={style.items}>
            <div>
                <div className={style.item} onClick={()=>navi("/setting/fontsize")}>
                    字体大小设置
                </div>
                <div className={style.item} onClick={()=>navi("/setting/privacy")}>隐私设置</div>
                <div className={style.item} onClick={()=>navi("/setting/pwd")}>更改密码</div>
                <div className={style.item} onClick={()=>navi("/setting/security")}>安全设置</div>

                <div style={{borderBottom:"1px solid gray",margin:"4px 0"}}></div>

                <div style={{borderBottom:"1px solid gray",margin:"4px 0"}}></div>
                <div className={style.item} onClick={logout}>退出登录</div>
            </div>
        </div>
    </div>

    function logout() {
        Confirm.confirm("确定要退出吗？").then(()=>{
            localStorage.clear()
            indexedDB.databases().then((dbs)=>{
                dbs.forEach(db=>{
                    indexedDB.deleteDatabase(db.name)
                })
            })
            unsubscribeNotify()
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            window.location.href="/"
        }).catch(()=>{})
    }


    

    
}
















