import { Navigate, Route, Routes } from 'react-router-dom'
import style from './index.module.css'

import PhoneBottom from '../header/phone.bottom'

import Flash from '../flash/'
import Square from "../suqare"
import Topic from '../topic/index.jsx'
import ProfileBase from '../user/profile/index.jsx'
import SettingBase from '../setting/base.jsx'
import UserPointsDetail from '../user/point'
import Notice from '../notice/index.jsx'
import Attendant from '../attendant/index.jsx'
import VIPHome from '../vip/home.jsx'
import Order from '../vip/order'
import SquareHint from '../suqare/hint.jsx'
import { Followers } from '../user/profile/followers.jsx'
import User from '../user/wrapper.jsx'
import GameMine from '../mine/index.jsx'
import LocatePostFlashReply from '../locate/index.jsx'
import { AvatarChange } from '../user/avatar/index.jsx'


export default function Home(){

    const fontSize = localStorage.getItem('fontSize')
    const tmpStyle = {
        fontSize:fontSize ? fontSize:'16px'
    }
    
    return<div className={style.container} style={tmpStyle}>
        <div className={style.content}>
            <Routes>
                <Route path="/" element={<Navigate to={'/flash'} />} />

                <Route path='/locate' element={<LocatePostFlashReply />}/>

                <Route path="/flash/*" element={<Flash />}/>
                <Route path="/topic/*" element={<Topic />} />

                <Route path="/square/hint" element={<SquareHint />} />
                <Route path="/square/*" element={<Square />} />
                
                <Route path="/user/points/:userId" element={<UserPointsDetail />} />
                <Route path='/user/profile/:userId' element={<ProfileBase />} />
                
                <Route path='/user/vip/order' element={<Order />} />
                <Route path='/user/vip//*' element={<VIPHome />} />

                <Route path="/user" element={<User />} />  
                <Route path='/user/avatarChange' element={<AvatarChange />} />
                <Route path='/user/followers' element={<Followers />} />
                <Route path="/setting/*" element={<SettingBase />} />

                <Route path='/notice' element={<Notice />} />

                <Route path='/attendant' element={<Attendant />} />

                <Route path='/game/mine' element={<GameMine />} />
            </Routes>
        </div>
        <div className={style.bottom} >
            <PhoneBottom />
        </div>
    </div>
}