import { Input } from "antd"
import { useState } from "react"

import style from "./css/age.module.css"
export default function AgeEditor(props){

    const [value,setValue] = useState(props.value==null?18:props.value)

    return<div className={style.container}>
        <div>
            <div>
                <p style={{fontWeight:'bold',color:'goldenrod'}}>
                    请填写 15-99 之间的两位数 
                </p>
            </div>
            <Input
                style={{height:'3em',fontSize:'2em'}} 
                value={value} 
                placeholder="请输入两位纯数字" onChange={e=>{
                    setValue(e.target.value)
                    props.back(e.target.value)
                }} />
        </div>
    </div>
}