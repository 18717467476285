



// 使用兑换码
import { useState } from 'react'
import style from './index.module.css'
import { axios } from '../../../axios'
import { useNavigate } from 'react-router-dom'
export function VIPCode(){
    const navi = useNavigate()

    const [code, setCode] = useState('')
    return <div className={style.container}>
        <h3>使用兑换码</h3>
        <div className={style.inputHolder}>
            <input type="text" placeholder="请输入兑换码"
                onChange={v=>setCode(v.target.value)} />
            <button onClick={use}>兑换</button>
        </div>
    </div>

    function use() {
        if(!code){
            alert('请输入兑换码')
            return
        }
        axios.post('/api/vip/card/use',{
            code
        }).then((({data})=>{
            if(data.code===200){
                alert('兑换成功')
                navi("/user/vip/change-record")
            }else{
                alert(data.msg)
            }
        })).catch(e=>{
            alert('兑换失败,请联系客服')
        })
    }
}