import { useEffect, useState } from "react"
import style from "./index.module.css"

export default function Page({total,current,pageSize,onChange}) {
  


    const totalPage = Math.ceil(total/pageSize)
    const [currentInner,setCurrentInner] = useState(current)
    //展示格式如   上一页 input(里面显示当前页) / 总页数 下一页
    //上一页
    function prev(){
      if(current>1){
        onChange(current-1)
      }
    }
    //下一页
    function next(){
      if(totalPage === 0){
        return
      }
      if(current<totalPage){
        onChange(current+1)
      }
    }
    //input框
    function inputChange(e){
      let value = e.target.value
      //只能输入数字或空
        value = value.replace(/\D/g, '');
        if(value === '' || (value > 0 && value <= totalPage)){
          setCurrentInner(value)
          return
        }
    }

    useEffect(()=>{
        setCurrentInner(current)
    },[current])

    //
    if (total === 0) {
        return null; // 或者显示一条消息给用户
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter' || e.keyCode === 13) { // 兼容不同的浏览器
          let value = e.target.value;
          value = value.replace(/\D/g, '');
    
          if (value > 0 && value <= totalPage) {
            setCurrentInner(value);
            // 更新父组件的current值
            onChange(parseInt(value, 10));
          }
        }
      }                                               

    return <div className={style.page}>
        <span className={[style.pre,current===1?style.disable:''].join(" ")} onClick={prev}>
            <svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg>
        </span>
        <span className={style.center}>
            <input type="text" value={currentInner} onKeyDown={handleKeyDown} onChange={inputChange}/>
            <span style={{color:'#888'}}>/</span>
            <span style={{color:'#888',fontSize:'14px'}}>{totalPage}</span>
        </span>
        <span className={[style.next,(current===totalPage||totalPage===0)?style.disable:''].join(' ')} onClick={next}>
            <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg>
        </span>
    </div>
}

