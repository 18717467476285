import { Route, Routes, useNavigate } from "react-router-dom";
import VIP from ".";
import { axios } from "../../axios";
import { useEffect, useState } from "react";
import { OrderList } from "./order/list";
import { VIPCode } from "./code";
import { VIPChangeRecord } from "./change-record";


export default function VIPHome(){
    const navi = useNavigate()
    useEffect(()=>{
        getUnfinishedOrder()
        // eslint-disable-next-line
    },[])

    const [checked,setChecked] = useState(false)

    return <Routes>
        <Route path='/' element={checked?<VIP />:<></>} />
        <Route path="order-list" element={<OrderList />} />
        <Route path="code" element={<VIPCode />} />
        <Route path="change-record" element={<VIPChangeRecord />} />
    </Routes>


    function getUnfinishedOrder() {
        axios.get("/api/pay/order/unfinished",{

        }).then(({data})=>{
            if(data.code===200){
                setChecked(true)
                let order = data.data
                if(order){
                    navi('/user/vip/order?id='+order.id)
                }
            }
        })
    }
}