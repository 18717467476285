

// 话题详情页 该话题被精选的话题列表

import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import style from "./selected.module.css"
import Page from "../../my-com/page"
export function SelectedList({page,list,getSelected}){
    const navi = useNavigate()


    useEffect(()=>{
        getSelected(page)
        // eslint-disable-next-line
    },[page])

    return <div className={style.posts}>
        {list.list.map(e=><div key={e.id} className={style.post}>
            <div className={style.title}>
                <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
            </div>
            <div className={style.description} onClick={()=>navi('/topic/post/detail/'+e.id)}>
                {e.description}
            </div>
        </div>)}

        <div style={{padding:'1em 0'}}>
            <Page 
                total={list.total}
                current={list.page}
                pageSize={18}
                onChange={p=>getSelected(p,true)}
            />
        </div>
    </div>
    
}