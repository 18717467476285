import { useContext, useState } from "react"
import { SusparContext } from "../../../context"




import style from "./index.module.css"
export function FontSizeSet(){
    const SUSPAR = useContext(SusparContext)
    const [tmpSize,setTmpSize] = useState(
        localStorage.getItem('fontSize')?localStorage.getItem('fontSize'):'16px'
    )

    return<div className={style.container}>
        <p style={{fontWeight:"bolder"}}>字体大小设置</p>
        <div style={{textAlign:'center'}}>这是文本大小</div>
        <div className={style.btns}>
            <div>
                <span className={tmpSize==='14px'?style.active:''} onClick={()=>{
                    localStorage.setItem('fontSize','14px')
                    SUSPAR.changeFontSize()
                    setTmpSize('14px')
                }}>更小</span>
                <span className={tmpSize==='16px'?style.active:''} onClick={()=>{
                    localStorage.setItem('fontSize','16px')
                    SUSPAR.changeFontSize()
                    setTmpSize('16px')
                }}>小</span>
                <span  className={tmpSize==='18px'?style.active:''} onClick={()=>{
                    localStorage.setItem('fontSize','18px')
                    SUSPAR.changeFontSize()
                    setTmpSize('18px')
                }}>中</span>
                <span className={tmpSize==='20px'?style.active:''} onClick={()=>{
                    localStorage.setItem('fontSize','20px')
                    SUSPAR.changeFontSize()
                    setTmpSize('20px')
                }}>大</span>
                <span className={tmpSize==='22px'?style.active:''} onClick={()=>{
                    localStorage.setItem('fontSize','22px')
                    SUSPAR.changeFontSize()
                    setTmpSize('22px')
                }}>超大</span>
            </div>
        </div>
    </div>
}