import { useEffect, useState } from "react"
import { axios } from "../../../../axios"
import { Link } from "react-router-dom"


import style from "./archived.posts.module.css"
import { localDate } from "../../../../tools"
import Page from "../../../my-com/page"

// 我的话题
export default function ArchivedPosts({userId}){

    const [postList,setPostList] = useState({
        list:[],
        total:0,
        page:1
    })

    useEffect(()=>{
        getMyPosts(1)
        // eslint-disable-next-line
    },[])

    return <div className={style.container}>
        <div>
            {postList.list.map(e=><div key={e.id} className={style.post}>
                    <p><Link to={'/topic/post/detail/'+e.id}>{e.title}</Link></p>
                    <span className={style.ss}>
                        <span>💬{e.replyCount}</span>
                        <span>😋{e.star}</span>
                        <span>🥵{e.unstar}</span>
                        <span className={style.date}>{localDate(e.createDatetime)}</span>
                    </span>
                </div>)}


                <div style={{margin:'1em 0'}}>
                    <Page
                        current={postList.page}
                        total={postList.total}
                        pageSize={18}
                        onChange={(i)=>{
                            getMyPosts(i)
                        }}
                    />
                </div>
        </div>
    </div>

    function getMyPosts(page) {
        axios.get("/api/topic/post/of-user/archived",{
            params:{
                page:page - 1,
                userId
            }
        }).then(({data})=>{
            if(data.code === 200){
                setPostList({
                    ...postList,
                    total:data.data.total,
                    list:data.data.list,
                    page:page
                })
            }
        }).catch(err=>{console.log(err)})
    }
}