


//

import { useEffect, useState } from "react"
import { axios } from "../../../axios"
import { Button } from "antd"

import style from "./index.module.css"
import Msg from "../../my-com/Msg"
export default function Security(){
    const [config,setConfig] = useState({
        email:''
    })
    const [inputing,setInputing] = useState(false)

    useEffect(()=>{
        getConfig()
    },[])
    return <div className={style.bg}>
        <h3>安全设置</h3>
        <p>当你忘记密码时，可通过以下方式重置密码</p>

        <div className={style.inputContainer} onClick={()=>setInputing(true)}>
            <span>邮箱</span>
            {!(config.email===null||config.email==='')&&<span>{config.email}&nbsp;&nbsp;&gt;</span>}
            {(config.email===null||config.email==='') && <span>未绑定&nbsp;&nbsp;&gt;</span>}
        </div>


        {inputing&&<div className={style.addBg}>
            <div>
                <p>
                    <input defaultValue={config.email} onChange={v=>setConfig({email:v.target.value})} />
                </p>

                <p>
                    <Button type="dashed" danger onClick={()=>submitEmail()}>提交</Button>&nbsp;&nbsp;
                    <Button type="dashed" onClick={()=>setInputing(false)}>取消</Button>
                </p>
            </div>
        </div>}
        
    </div>

    function getConfig() {
        axios.get("/api/user/security",{

        }).then(({data})=>{
            if(data.code===200){
                setConfig({
                    ...data.data
                })
            }
        }).catch(err=>{})
    }

    function submitEmail(){
        if(!config.email || config.email.trim()===''){
            Msg.msg("不能为空")
            return
        }
        axios.put("/api/user/security/email",{
            email:config.email
        }).then(({data})=>{
            if(data.code===200){
                Msg.msg("提交成功")
                setInputing(false)
            }
        })
    }
}