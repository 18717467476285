import { useContext } from "react"
import { PAIRCONTEXT } from "../context"

import ConfirmProfile from "./confirm-profile"
import ConfirmFace from "./confirm-face"
import NowLover from "./lover"
import AfterAWeek from "./after-week"
import { BreakUp } from "./breakup"


export default function SquareFlow(){
    
    const PAIRC = useContext(PAIRCONTEXT)
    const showStatus = PAIRC.flow.showStatus

    return <div>
        <div>
            {showStatus==='CREATED' && <ConfirmProfile />} 
            {showStatus==='SAY_HELLO' && <ConfirmProfile />} 
            {showStatus==='FACE_PAIRING' && <ConfirmFace />} 
            {showStatus==='READY_BE_LOVER' && <AfterAWeek />} 
            {showStatus==='NOW_LOVER' && <NowLover />}
            {showStatus==='BREAK_UP' && <BreakUp />} 
        </div>
    </div>


}




