import { useContext, useEffect, useState } from "react"
import { PAIRCONTEXT } from "../../context"

import style from "./index.module.css"
import { Dislike, Like } from "./like"
import { axios } from "../../../../../axios"
import { LeftSquareOutlined, RightSquareOutlined } from "@ant-design/icons"
import { localDate } from "../../../../../tools"

export default function AfterAWeek(){
    const PAIRC = useContext(PAIRCONTEXT)
    const sessionId = PAIRC.flow.pairSessionId

    const [show,setShow] = useState({
        show:false,
        type:'',//like or dislike,
    })
    const [confirmRecord,setConfirmRecord] = useState({
        //sessionFinished
    })

    useEffect(()=>{
        getConfirmRecord()
        // eslint-disable-next-line
    },[])




    return <div>
        <div>
            <h2 style={{textAlign:"center"}}>一周接触</h2>
        </div>
        {confirmRecord.dateOfStartAfterWeek?<>
        <div className={style.hint}>
            <div className={style.a}>
                <p>{localDate(confirmRecord.dateOfStartAfterWeek)},你们决定开始试相处一周，请在相处七天后及时来确认是否要在一起。</p>
            </div>
            <div className={style.b}>
                <p>路上人往来匆匆，</p>
                <p>每个人都有自己的目的地。</p>
                <p>他们向东向南向西向北，</p>
                <p>如果可以，希望能与你一起，向哪里都可以。</p>
            </div>
        </div>


        <div className={style.decision}>
            {confirmRecord.myAfterWeek && <div>
                <p>
                    <span className={style.btn}>我的决定:</span>&nbsp;
                    {confirmRecord.myAfterWeek.toNext?<span>继续相处</span>:<span>不再继续</span>}
                </p>
                <div>
                    {confirmRecord.myAfterWeek.comment}
                    <p className={style.date}>{localDate(confirmRecord.myAfterWeek.createDatetime)}</p>
                </div>
            </div>}
            {confirmRecord.hisAfterWeek && <div>
                <p><span className={style.btn}>他的决定:</span>&nbsp;{confirmRecord.hisAfterWeek.toNext?<span>继续接触</span>:<span>停止接触</span>}</p>
                <div>
                    {confirmRecord.hisAfterWeek.comment}
                    <p className={style.date}>{localDate(confirmRecord.hisAfterWeek.createDatetime)}</p>
                </div>
            </div>}
        </div>


        {!confirmRecord.myAfterWeek&&!confirmRecord.sessionFinished&&<div>
            <div className={style.confirmBtns}>
                <div>是否愿意成为结为伴侣?</div>
                <div>
                    <span onClick={()=>{
                        setShow({show:true,type:"like"})
                    }}>确认</span>
                    <span onClick={()=>{
                        setShow({show:true,type:"dislike"})
                    }}>放弃</span>
                </div>
            </div>
        </div>}



        {show.show&&<div>
            {show.type==='like'&&<Like sessionId={sessionId} 
            close={(submitted)=>{
                setShow({show:false,type:''})
                if(submitted){
                    getConfirmRecord()
                }
            }} />}   

            {show.type==='dislike' && <Dislike sessionId={sessionId} close={(submitted)=>{
                setShow({show:false,type:''})
                if(submitted){
                    getConfirmRecord()
                }
            }} />}
        </div>}


        <div className={style.preOrNext}>
            <span className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"FACE_PAIRING"})}>
                <LeftSquareOutlined />
            </span>
            {PAIRC.canToNext('READY_BE_LOVER') && 
                <span className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"NOW_LOVER"})}>
                <RightSquareOutlined />
                </span>}
        </div>

        </>:<></>}
    </div>


    //如果已经结束，请求会315.交给axios处理
    function getConfirmRecord(){
        axios.get("/api/square/flow/after-week",{
            params:{
                sessionId
            }
        }).then(({data})=>{
            if(data.code===200){
                setConfirmRecord(data.data)
                let sessionStatus = data.data.sessionStatus
                PAIRC.setFlow({
                    ...PAIRC.flow,
                    status:sessionStatus,
                })
            }
        }).catch(err=>{console.log(err)})
    }
}