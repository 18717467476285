import { useEffect, useState } from "react"
import { axios } from "../../../axios"

import style from "./index.module.css"
import { localDate } from "../../../tools"
import { CloseSquareFilled, SettingFilled } from "@ant-design/icons"
import { Button, Input } from "antd"
import Confirm from "../../my-com/confirm"
export function Lover() {

  const [lover, setLover] = useState({
    inLove: null, //true false
    loverName: null,
    loveInDatetime: null,
  })
  const [showSetting, setShowSetting] = useState(false)

  useEffect(()=>{
    getMyLover()
    // eslint-disable-next-line
  },[])

  return <div className={style.container}>

    <div className={style.header}>
      <span><b>我的爱人</b></span> 
	  {lover.inLove&&<span style={{padding:'4px'}} onClick={()=>setShowSetting(true)}>
	  	<SettingFilled />
	  </span>}
    </div>
    
    {lover.inLove!==null&&<>
      {lover.inLove?<div>
        <div className={style.loverName}>
          	<div>
				<span># </span>
				<span>{lover.loverName}</span>
			</div>
        </div>

		<div className={style.loveTime}>
			<span className={style.a}>{localDate(lover.loveInDatetime)}</span>
			<span className={style.b}>你们决定在一起.</span>
		</div>
      </div>:<div>
          你还没有确定关系的爱人，请到设置里打开寻他功能开始你的缘分吧。
      </div>}
    </>}

    {showSetting&&<Setting close={()=>setShowSetting(false)} />}
  </div>

  function getMyLover() {
    axios.get("/api/square/my-lover",{
    }).then(({data})=>{
      if(data.code===200){
        setLover({
          inLove: data.data.inLove,
          loverName: data.data.loverName,
          loveInDatetime: data.data.loveInDatetime
        })
      }
    }).catch(err=>{})
  }
}

function Setting({close}){
	const [reason,setReason] = useState(null)

  return <div className={style.setting}>
	<div>
		<span style={{padding:'4px',fontSize:'24px'}} 
			onClick={()=>close()}>
				<CloseSquareFilled />
		</span>
		<div>	
			<p>相知相遇不易。请认真考虑。</p>
			<Input.TextArea
				placeholder="请填写结束关系的原因"
				value={reason}
				autoSize
				onChange={(e)=>setReason(e.target.value)}
			/>
			<Button type="dashed" danger onClick={breakup}>结束关系</Button>
		</div>
	</div>
  </div>

  function breakup() {
	Confirm.confirm("确定要结束关系吗？").then(()=>{
		if(!reason){
			alert("请填写结束关系的原因")
			return
		}
		axios.post("/api/square/break-up",{
			reason
		}).then(({data})=>{
			if(data.code===200){
				alert("关系已经结束，请去设置手动打开寻他功能。")
				close()
			}
		}).catch(()=>{})
	}).catch(()=>{})
  }
}

