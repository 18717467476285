import {useState } from "react"
import { getProvince } from "../../../../tools"



import style from "./css/city.module.css"
export default function CityEditor(props){
    
    
    const [citys,setCitys] = useState(getProvince(props.value.province)?
        getProvince(props.value.province):[])   

    const [location,setLocation] = useState({
        province:props.value.province?props.value.province:null,
        city:props.value.city?props.value.city:null
    })
    return<div className={style.container}>
        
        <div className={style.header}>
            <span>{location.province}</span>
            <span>{location.city}</span>
        </div>
        <div>
            <div className={style.left}>
                {getProvince().map(v=><div key={v} className={[style.province,location.province===v?style.active:''].join(' ')} 
                    onClick={()=>{
                        setLocation({province:v,city:null})
                        setCitys(getProvince(v))
                        props.back({province:v,city:null})
                    }}>
                    {v}
                </div>)}
            </div>
            <div className={style.right}>
                {citys.map(v=><div key={v} className={[style.city,location.city===v?style.active:''].join(' ')} 
                    onClick={()=>{
                        setLocation({...location,city:v})
                        props.back({province:location.province,city:v})
                    }}>
                    {v}
                </div>)}
            </div>
        </div>


    </div>
}