import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { axios } from "../../axios";

import style from "./index.module.css"
import { AvatarBaseURL, CACHE, localDate } from "../../tools";
import { AlertFilled, BellFilled, BulbFilled, CustomerServiceFilled, NotificationFilled, SettingFilled, SmileFilled } from "@ant-design/icons";
export default function UserLogged(){

    const navigate = useNavigate();
    const navi = useNavigate()
    const [profile,setProfile] = useState({})
    const [level,setLevel] = useState(0)
    const [checkInStatus,setCheckInStatus] = useState(true)
    const [banStatus,setBanStatus] = useState({
        banedStatus:0,
        banedToDatetime:"",
        banedReason:""
    })
    const [vipInfo,setVipInfo] = useState({
        vip:null,
        vipRestDays:-1,
        vipHadDays:-1,
        vipEndDate:null, 
    })
    const [noticeCount,setNoticeCount] = useState({
        notice:0,//通知
        attendant:0,//客服消息
    })

    useEffect(()=>{
        const isLogin = localStorage.getItem("info")!==null
        if(!isLogin){
            navigate("/login")
        }else{
            getUserProfile()
            getUserPoints()
            getCheckInStatus()
        }
        // eslint-disable-next-line
    },[])


    return<div className={style.container}>
        <div className={style.top}>
            <div className={style.user} onClick={()=>navigate("/user/profile/"+profile.userId)}>
                <div className={style.left}>
                    {profile.id&&<img
                        src={AvatarBaseURL+(profile.avatar?profile.avatar:'avatar/default/1.png')} alt="avatar" />
                    }
                </div>
                <div className={style.right}>
                    <span className={style.nickname}>
                        {profile.nickname}
                    </span>
                    <span>
                        帐号：{profile.username}
                    </span>
                </div>
            </div>

            <div className={style.userNum}>
                <span className={style.pointsLevel} onClick={()=>navi("/user/points/"+profile.userId)}>等级:{level}</span>
                {checkInStatus?<span>已签到</span>:<span onClick={checkIn}>签到</span>}
            </div>

            {vipInfo.vip!==null && <div className={style.userNum}>
                {vipInfo.vip&&<>
                    <span onClick={()=>navi("/user/vip")}>成员卡已有{vipInfo.vipHadDays}天</span>
                    <span onClick={()=>navi("/user/vip")}>剩余{vipInfo.vipRestDays}天</span>
                </>}

                {!vipInfo.vip&&<>
                    <span onClick={()=>navi("/user/vip")}>成员卡已到期</span>
                </>}
            </div>}
            {vipInfo.vip===null && <div className={style.userNum}>
                {<>
                    <span>成员卡已有 0天</span>
                    <span>剩余 0天</span>
                </>}
            </div>}

            {banStatus.banedStatus===1&&<div className={style.ban}>
                <span>禁言中</span>
                <span>解除时间:{localDate(banStatus.banedToDatetime)}</span>
                <span>原因:{banStatus.banedReason}</span>
            </div>}

            <div className={style.followerContainer}>
                <div onClick={()=>navi("/user/followers?tab=fans&page=1")}>
                    <span>{profile.followCount}</span>
                    <span>关注</span>
                </div>
                <div onClick={()=>navi("/user/followers?tab=fans&page=1")}>
                    <span>{profile.fansCount}</span>
                    <span>粉丝</span>
                </div>
            </div>
        </div>

        <div className={style.more} style={{paddingBottom:'32px'}}>
            <div onClick={()=>navi("/notice")}>
                <span>
                    <NotificationFilled style={{color:'chocolate'}} />&nbsp;&nbsp;通知{noticeCount.notice>0&&<sup style={{color:'red',padding:'4px'}}>{noticeCount.notice}</sup>}
                </span>
            </div>
            <div onClick={()=>navi('/setting')}>
                <span><SettingFilled style={{color:'#2196F3'}} />&nbsp;&nbsp;设置</span>
            </div>
            <div onClick={()=>navi("/attendant")}>
                <span><CustomerServiceFilled  style={{color:'darkred'}}/>&nbsp;&nbsp;联系客服{noticeCount.attendantUnread?<sup style={{color:"red"}}>未读</sup>:<></>}</span>
            </div>
            <div onClick={()=>navi("/setting/rules")}>
                <span><BulbFilled  style={{color:'#ff9800'}}/>&nbsp;&nbsp;社区规则</span>
            </div>
            <div onClick={()=>navi("/setting/about")}>
                <span><AlertFilled style={{color:'green'}} />&nbsp;&nbsp;关于</span>
            </div>
            <div onClick={()=>navi("/setting/help")}>
                <span><BellFilled style={{color:'#F44336'}} />&nbsp;&nbsp;帮助•必读</span>
            </div>
            <div onClick={()=>navi("/game/mine")}>
                <span><SmileFilled style={{color:'#ff9800'}} />&nbsp;&nbsp;扫个雷</span>
            </div>
            {/* <div>
                <span onClick={()=>navi('/test')}>TEST</span>
            </div> */}
        </div>

    </div>


    function getUserProfile(){
        let userInfo = localStorage.getItem("info")
        if(userInfo){
            let userId = JSON.parse(userInfo).user.id
            new CACHE('profiles').get(userId)
            .then(data=>{
                setProfile(data)
            }).catch(err=>{
                axios.get('/api/user/profile')
                .then(({data})=>{
                    if(data.code===200){
                        let profile = data.data
                        setProfile(profile)
                        new CACHE('profiles').add(userId,profile)
                    }
                }).catch(err=>{
                    console.log(err)
                })
            })
        }
    }


    function getUserPoints(){
        let userInfo = localStorage.getItem("info")
        if(userInfo){
            let userId = JSON.parse(userInfo).user.id
            let chachedUserPoints = localStorage.getItem("userPoints")
            if(chachedUserPoints){
                let chachedData = JSON.parse(chachedUserPoints)
                setLevel(chachedData.level)
                asyncGetUserPoints(userId)
            }else{
                asyncGetUserPoints(userId)
            }
        }
    }
    async function asyncGetUserPoints(userId){
        axios.get('/api/user/points',{
            params:{
                userId
            }
        }).then(({data})=>{
            if(data.code===200){
                let points = data.data
                setLevel(points.level)
                localStorage.setItem("userPoints",JSON.stringify(data.data))
            }
        }).catch(err=>{
            console.log(err)
        })
    }


    //获取签到状态 vip信息 禁言状态 客服消息未读
    async function getCheckInStatus(){
        let userInfo = localStorage.getItem("info")
        if(userInfo){
            let userId = JSON.parse(userInfo).user.id

            let checkInStatus = localStorage.getItem("checkInStatus")
            if(checkInStatus){
                let cachedData = JSON.parse(checkInStatus)
                setCheckInStatus(cachedData.checkIn)
                setBanStatus({
                    banedStatus:cachedData.banedStatus,
                    banedToDatetime:cachedData.banedToDatetime,
                    banedReason:cachedData.banedReason
                })
                setVipInfo({
                    vip:cachedData.vip,
                    vipHadDays:cachedData.vipHadDays,
                    vipRestDays:cachedData.vipRestDays,
                    vipStartDate:cachedData.vipStartDate,
                    vipEndDate:cachedData.vipEndDate
                })
                setNoticeCount({
                    notice:cachedData.noticeCount,
                    attendantUnread:cachedData.attendantUnread
                })
                check(userId)
            }else{
                check(userId) 
            }
            
        }
    }
    async function check(userId){
        axios.get('/api/user/check-in-state',{
            params:{
                userId
            }
        }).then(({data})=>{
            if(data.code===200){
                data = data.data
                setCheckInStatus(data.checkIn)
                setBanStatus({
                    banedStatus:data.banedStatus,
                    banedToDatetime:data.banedToDatetime,
                    banedReason:data.banedReason
                })
                setVipInfo({
                    vip:data.vip,
                    vipHadDays:data.vipHadDays,
                    vipRestDays:data.vipRestDays,
                    vipStartDate:data.vipStartDate,
                    vipEndDate:data.vipEndDate
                })
                setNoticeCount({
                    notice:data.noticeCount,
                    attendantUnread:data.attendantUnread
                })
                localStorage.setItem("checkInStatus",JSON.stringify(data))
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    //签到
    function checkIn(){
        let userInfo = localStorage.getItem("info")
        if(userInfo){
            let userId = JSON.parse(userInfo).user.id
            axios.get('/api/user/check-in',{
                params:{
                    userId
                }
            }).then(({data})=>{
                if(data.code===200){
                    setCheckInStatus(true)
                    getCheckInStatus()
                }
            }).catch(err=>{
                console.log(err)
            })
        }
    }
}
